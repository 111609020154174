
import SideNav from "./SideNav";
import TopBar from "./TopBar";
import React, { useState,  useEffect, useRef,useContext } from 'react';
import axios from 'axios';
import { Link, useParams, useNavigate } from "react-router-dom";
import Select from 'react-select';
import Swal from "sweetalert2";
import { elements } from "chart.js";
import ReactPaginate from 'react-paginate';
import $ from 'jquery';
import placeholderImage from '../img/image_placeholder.png';
import noImagePlaceholder from '../img/no_image_placeholder.png';
import UserContext from './UserContext';
import moment from 'moment';
import { Modal, Carousel, Button } from 'react-bootstrap';
function LeadsDetails(){

    const API_URL = process.env.REACT_APP_API_URL;
    const LARAVEL_API_URL = process.env.REACT_APP_LARAVEL_API;
    const navigate = useNavigate();
    const userContext = useContext(UserContext);  

    const [isLoading, setIsLoading] = useState(false);
    const [mergedArray, setMergedArray] = useState([]);
    const [customer, setCustomer] = useState([]);
    const [customerType, setCustomerType] = useState([]);
    const [assign_to, setAssign_to] = useState([]);
    const [email_template, setEmail_template] = useState([]);
    const [sms_template, setSms_template] = useState([]);
    const [note_template, setNote_template] = useState([]);
    const [team, setTeam] = useState([]);
    const [upload_template, setUpload_template] = useState([]);
    const [pin_template, setPin_template] = useState([]);
    const [customerById, setCustomerById] = useState([]);
    const [emailLogById, setEmailLogById] = useState([]);
    const [smsLogById, setSmsLogById] = useState([]);
    const [meetLogById, setMeetLogById] = useState([]);
    const [noteLogById, setNoteLogById] = useState([]);
    const [uploadLogById, setUploadLogById] = useState([]);
    const [inquiryById, setInquiryById] = useState([]);
    const { id } = useParams();

    const [minPrice, setMinPrice] = useState("");
    const [maxPrice, setMaxPrice] = useState("");
    const [minKilometer, setMinKilometer] = useState("");
    const [maxKilometer, setMaxKilometer] = useState("");
    const [makeOptions, setMakeOptions] = useState([]);
    const [modelOptions, setModelOptions] = useState([]);
    const [bodyStyleOptions, setBodyStyleOptions] = useState([]);
const [engineOptions, setEngineOptions] = useState([]);
const [exteriorColorOptions, setExteriorColorOptions] = useState([]);
const [transmissionOptions, setTransmissionOptions] = useState([]);
const [driveTypeOptions, setDriveTypeOptions] = useState([]);
const [doorsOptions, setDoorsOptions] = useState([]);
const [stockNoOptions, setStockNoOptions] = useState([]);
const [vehicle_name, setVehicle_name] = useState("");
    const [selectedMakes, setSelectedMakes] = useState([]);
    const [selectedModels, setSelectedModels] = useState([]);
    const [selectedBodyStyles, setSelectedBodyStyles] = useState([]);
const [selectedEngines, setSelectedEngines] = useState([]);
const [selectedExteriorColors, setSelectedExteriorColors] = useState([]);
const [selectedTransmissions, setSelectedTransmissions] = useState([]);
const [selectedDriveType, setSelectedDriveType] = useState([]);
const [selectedDoors, setSelectedDoors] = useState([]);
const [selectedStockNo, setSelectedStockNo] = useState([]);
    const [inventory, setInventory] = useState([]);
    const [selectedInventoryType, setSelectedInventoryType] = useState("false");
    const [disableMakeOptions, setDisableMakeOptions] = useState(true);
    const [loading, setLoading] = useState(false);
    const [filteredResults, setFilteredResults] = useState([]);
    const [filteredInventory, setFilteredInventory] = useState([]);
    const [isSearch, setIsSearch] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);

    const [settingsVisible, setSettingsVisible] = useState(false);
    const [inqVisible, setInqVisible] = useState(false);
    const [callVisible, setCallVisible] = useState(false);
    const [emailVisible, setEmailVisible] = useState(false);
    const [textVisible, setTextVisible] = useState(false);
    const [pinVisible, setPinVisible] = useState(false);
    const [meetVisible, setMeetVisible] = useState(false);
    const [noteVisible, setNoteVisible] = useState(false);
    const [uploadVisible, setUploadVisible] = useState(false);
    const [inqueryVisible, setInqueryVisible] = useState(true);
    const [email_error_list, setEmailErrorList] = useState([]);
    const [meetingErrorList, setMeetingErrorList] = useState([]);
    const [noteErrorList, setNoteErrorList] = useState([]);
    const [uploadErrorList, setUploadErrorList] = useState([]);
    const [error_list, setErrorList] = useState([]);
    const [description, setDescription] = useState('');

    const [template, setTemplate] = useState("");
    const [text_template, setText_template] = useState("");
    const [note_template_select, setNote_template_select] = useState("");
    const [team_select, setTeam_select] = useState([]);
    const [upload_template_select, setUpload_template_select] = useState("");
    const [pin_template_select, setPin_template_select] = useState("");
    const [template_details, setTemplate_details] = useState([]);
    const [text_template_details, setText_template_details] = useState([]);
    const [note_template_details, setNote_template_details] = useState([]);
    const [upload_template_details, setUpload_template_details] = useState([]);
    const [pin_template_details, setPin_template_details] = useState([]);
    const [subject, setSubject] = useState("");
    const [note_subject, setNote_subject] = useState("");
    const [upload_subject, setUpload_subject] = useState("");
    const [message, setMessage] = useState("");
    const [email, setEmail] = useState("");
    const [first_name, setFirst_name] = useState("");
    const [phone_no, setPhone_no] = useState("");
    const [successMessage, setSuccessMessage] = useState('');
    const [images, setImages] = useState([]);
    const [videos, setVideos] = useState([]);
    const [previews, setPreviews] = useState([]);
    const [previewsVideo, setPreviewsVideo] = useState([]);
    const selectAllCheckboxRef = useRef();
    const [perPage, setPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [text_message, setText_message] = useState("");
    const [note_message, setNote_message] = useState("");
    const [upload_message, setUpload_message] = useState("");
    const [pin_message, setPin_message] = useState("");
    const [activeButton, setActiveButton] = useState(null);
    const [sortOrder, setSortOrder] = useState('newest'); 
    const [title, setTitle] = useState('');
    const [startDatetime, setStartDatetime] = useState('');
    const [endDatetime, setEndDatetime] = useState('');
    const [date, setDate] = useState('');
    const [time, setTime] = useState('');
    const [availableTimeSlots, setAvailableTimeSlot] = useState([]);
    const [makeError, setMakeError] = useState();
    const [modelError, setModelError] = useState();
    const [isImageLoading, setIsImageLoading] = useState(true);
    const [isUploadImageLoading, setIsUploadImageLoading] = useState(true);
    const [isPhotoLoading, setIsPhotoLoading] = useState(true);
    const [user_type, setUser_type] = useState("");
    const [activeTab, setActiveTab] = useState('documents'); 
    const editorContainer = useRef(null);
    const isEditorInitialized = useRef(false); // Track initialization
    const [showModal, setShowModal] = useState(false);

    
      // Filter the team based on the search input
      const filteredTeam = team.filter((member) =>
        `${member.first_name} ${member.last_name}`.toLowerCase().includes(searchQuery.toLowerCase())
      );
    

    const handleDateChange = (e) => {
       
        const selectedDate = e.target.value;
        setDate(selectedDate);
        axios.get(`${API_URL}/booked-appointments`, { params: { selectedDate } })
        .then(response => {
            const bookedTimes = response.data.map(time => {
                const [hour, minute] = time.split(':');
                const hour12 = (parseInt(hour) % 12) || 12;
                const ampm = parseInt(hour) < 12 ? 'AM' : 'PM';
                return `${hour12}:${minute} ${ampm}`;
            });
   
            const availableTimes = generateTimeSlots(bookedTimes);
       
        })
        .catch(error => {
            console.error('Error fetching available date-time pairs:', error);
        });
      };

      const isImage = (fileName) => {
        const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.webp'];
        return imageExtensions.some(ext => fileName.toLowerCase().endsWith(ext));
    };

    const isDocument = (fileName) => {
        const documentExtensions = ['.pdf', '.doc', '.docx', '.ppt', '.pptx', '.xls', '.xlsx', '.txt']; // Add more extensions if needed
        const fileExtension = fileName.toLowerCase().split('.').pop(); // Get the file extension
        
        return documentExtensions.includes(`.${fileExtension}`);
    };
      
      const formatMeetDate = (dateString) => {
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', options);
    };
    
    const formatMeetTime = (timeString) => {
        const options = { hour: '2-digit', minute: '2-digit', hour12: true };
        const date = new Date(`1970-01-01T${timeString}`);
        return date.toLocaleTimeString('en-US', options);
    };

      const generateTimeSlots = (bookedTimes) => {
        // Generate time slots from 9 AM to 4 PM
        const startTime = 9;
        const endTime = 16;
        const timeSlots = [];
    
        for (let hour = startTime; hour <= endTime; hour++) {
          const formattedHour = hour % 12 || 12;
          const ampm = hour < 12 ? 'AM' : 'PM';
          const timeSlot = `${formattedHour}:00 ${ampm}`;
          timeSlots.push(timeSlot);
        }
    
  
       // console.log(timeSlots.filter(timeSlot => !timeSlot.includes(bookedTimes)))

        const availableTimeSlots = timeSlots.filter(timeSlot => !bookedTimes.includes(timeSlot));
setAvailableTimeSlot(availableTimeSlots);
        return availableTimeSlots;
      };

      const formattedDate = new Date(date);
      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
      const formattedDateString = formattedDate.toLocaleDateString('en-US', options);

      const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
      };

      

      const filteredEmailHistory = emailLogById.filter((item) => {
        return item.subject.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.created_at.toLowerCase().includes(searchQuery.toLowerCase())  
      });

      
      const filteredMeetHistory = meetLogById.filter((item) => {
        return item.subject.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.created_at.toLowerCase().includes(searchQuery.toLowerCase())  
      });

      const filteredNoteHistory = noteLogById.filter((item) => {
        return item.subject.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.created_at.toLowerCase().includes(searchQuery.toLowerCase())  
      });

      const filteredUploadHistory = uploadLogById.filter((item) => {
        return item.subject.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.created_at.toLowerCase().includes(searchQuery.toLowerCase())  
      });

     

       const uploadImageClick = (imageURL) => {
        Swal.fire({
          imageUrl: imageURL,
          imageAlt: 'Uploaded Image',
          showCloseButton: true,
          showConfirmButton: false,
        });
      };


       
      const sortedSmsHistory = [...smsLogById]; // Make a copy of the original data

      if (sortOrder === 'oldest') {
          sortedSmsHistory.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
      } else {
          sortedSmsHistory.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      }

//       useEffect(() => {
//     const filteredOptions = mergedArray.filter((item) => {
//         return (
//             (selectedInventoryType === 'all' || item.global_inventory === selectedInventoryType) &&
//             (!selectedMakes.length || selectedMakes.includes(item.brand) || 
//             (selectedMakes.includes(item.brand) && selectedModels.includes(item.model_name))) &&
//             (!selectedExteriorColors.length || selectedExteriorColors.includes(item.exterior_color.toLowerCase())) &&
//             (!selectedTransmissions.length || selectedTransmissions.includes(item.transmission.toLowerCase())) &&
//             (!selectedBodyStyles.length || selectedBodyStyles.includes(item.body_type.toLowerCase())) &&
//             (!selectedEngines.length || selectedEngines.includes(item.engine_type.toLowerCase())) &&
//             (!selectedDriveType.length || selectedDriveType.includes(item.drive_type.toLowerCase())) &&
//             (!selectedDoors.length || selectedDoors.includes(item.door)) &&
//             (!selectedStockNo.length || selectedStockNo.includes(item.stock_no.toLowerCase())) &&
//             (!minPrice || item.price.sale_price >= minPrice) &&
//             (!maxPrice || item.price.sale_price <= maxPrice) &&
//             (!minKilometer || item.odometer >= minKilometer) &&
//             (!maxKilometer || item.odometer <= maxKilometer)
//         );
//     });

//     const bodyStyles = filteredOptions.map(row => row.body_type.toLowerCase());
//     setBodyStyleOptions([...new Set(bodyStyles)]);

//     const engines = filteredOptions.map(row => row.engine_type.toLowerCase());
//     setEngineOptions([...new Set(engines)]);

//     const exteriorColor = filteredOptions.map(row => row.exterior_color.toLowerCase());
//     setExteriorColorOptions([...new Set(exteriorColor)]);

//     const transmissions = filteredOptions.map(row => row.transmission.toLowerCase());
//     setTransmissionOptions([...new Set(transmissions)]);

//     const driveTypes = filteredOptions.map(row => row.drive_type.toLowerCase());
//     setDriveTypeOptions([...new Set(driveTypes)]);

//     const doors = filteredOptions.map(row => row.door);
//     setDoorsOptions([...new Set(doors)]);

//     const stockNos = filteredOptions.map(row => row.stock_no.toLowerCase());
//     setStockNoOptions([...new Set(stockNos)]);
// }, [mergedArray, selectedInventoryType, selectedMakes, selectedModels, selectedExteriorColors, selectedTransmissions, selectedBodyStyles, selectedEngines, selectedDriveType, selectedDoors, selectedStockNo, minPrice, maxPrice, minKilometer, maxKilometer]);

    const handleImageLoad = () => {
    setIsImageLoading(false);
};

const handleUploadImageLoad = () => {
    setIsUploadImageLoading(false);
};

const handlePhotoLoad = () => {
    setIsPhotoLoading(false);
};

   const showToast = (message) => {
  Swal.fire({
    icon: 'success',
    title: message,
    color:"white",
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3500,
    customClass: {
        popup: 'sweetalert-popup',
        title: 'sweetalert-title',
        icon: 'sweetalert-icon',
      },
      background: '#333',
      showCloseButton: true,
      padding: '4px', 
  });
};

const showToastFail = (message) => {
    Swal.fire({
      icon: 'error',
      title: message,
      color:"white",
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3500,
      customClass: {
          popup: 'sweetalert-popup',
          title: 'sweetalert-title',
          icon: 'sweetalert-icon',
        },
        background: '#333',
        showCloseButton: true,
        padding: '4px', 
    });
  };

  const handleUserType = async (event, customerId) => {
    setIsLoading(true);
    const newUserType = event.target.value;
    setCustomer(prevCustomer => prevCustomer.map(c => c.id === customerId ? { ...c, user_type: newUserType } : c));
    setUser_type(newUserType); // Set the new user type in the state
    try {
        // Fetch the existing customer data
        const res = await axios.get(`${API_URL}/edit-customer/${customerId}`);
        if (res.data.status === 200) {
            const customerData = res.data.customer;
            setCustomerType(customerData);

            // Prepare form data for the update request
            const formData = new FormData();
            formData.append('customer_photo', customerData.customer_photo);
            formData.append('user_type', newUserType); // Use the new user type
            formData.append('first_name', customerData.first_name);
            formData.append('last_name', customerData.last_name);
            formData.append('phone_no', customerData.phone_no);
            formData.append('email', customerData.email);
            formData.append('purchased_on', customerData.purchased_on);
            formData.append('country', customerData.country);
            formData.append('address', customerData.address);
            formData.append('apart_or_suite', customerData.apart_or_suite);
            formData.append('city', customerData.city);
            formData.append('province', customerData.province);
            formData.append('postal_code', customerData.postal_code);
            formData.append('employer_name', customerData.employer_name);
            formData.append('employer_address', customerData.employer_address);
            formData.append('employer_country', customerData.employer_country);
            formData.append('employer_apart_or_suite', customerData.employer_apart_or_suite);
            formData.append('employer_city', customerData.employer_city);
            formData.append('employer_state', customerData.employer_state);
            formData.append('employer_postalcode', customerData.employer_postalcode);
            formData.append('job_title', customerData.job_title);
            formData.append('monthly_income', customerData.monthly_income);
            formData.append('vehicle_type', customerData.vehicle_type);
            formData.append('hear_about', customerData.hear_about);

            // Send the update request
            const updateRes = await axios.post(`${API_URL}/update-customer/${customerId}`, formData);
            if (updateRes.status === 200) {
                showToast("Usertype updated!");
            }
        }


    } catch (error) {
        Swal.fire({
            text: error.response?.data?.message || 'An error occurred',
            icon: "error"
        });
    }
    setIsLoading(false);
};


const saveAssign = () => {
    setLoading(true);

    // Update local state
    setCustomer(prevCustomer => 
        prevCustomer.map(c => c.id === customerById[0].id ? { ...c, assign_to: team_select } : c)
    );
    setTeam_select(team_select);

    // Fetch the existing customer data
    axios.get(`${API_URL}/edit-customer/${customerById[0].id}`)
        .then(res => {
            if (res.data.status === 200) {
                const customerData = res.data.customer;
                let assignToList = [];
                try {
                    assignToList = JSON.parse(customerData[0].assign_to);
                    setTeam_select(assignToList);
                } catch (e) {
                    console.error("Error parsing assign_to:", e);
                }
                // Prepare form data for the update request
                const formData = new FormData();
                formData.append('customer_photo', customerData.customer_photo);
                formData.append('user_type', customerData.user_type); // Use the new user type
                formData.append('first_name', customerData.first_name);
                formData.append('last_name', customerData.last_name);
                formData.append('phone_no', customerData.phone_no);
                formData.append('email', customerData.email);
                formData.append('purchased_on', customerData.purchased_on);
                formData.append('country', customerData.country);
                formData.append('address', customerData.address);
                formData.append('apart_or_suite', customerData.apart_or_suite);
                formData.append('city', customerData.city);
                formData.append('province', customerData.province);
                formData.append('postal_code', customerData.postal_code);
                formData.append('employer_name', customerData.employer_name);
                formData.append('employer_address', customerData.employer_address);
                formData.append('employer_country', customerData.employer_country);
                formData.append('employer_apart_or_suite', customerData.employer_apart_or_suite);
                formData.append('employer_city', customerData.employer_city);
                formData.append('employer_state', customerData.employer_state);
                formData.append('employer_postalcode', customerData.employer_postalcode);
                formData.append('job_title', customerData.job_title);
                formData.append('monthly_income', customerData.monthly_income);
                formData.append('vehicle_type', customerData.vehicle_type);
                formData.append('hear_about', customerData.hear_about);
                formData.append('assign_to', JSON.stringify(team_select)); // Ensure it's a string

                // Send the update request
                axios.post(`${API_URL}/update-customer/${customerById[0].id}`, formData)
                    .then(updateRes => {
                        if (updateRes.data.status === 200) {
                            showToast("Assigned successfully!");
                        } else {
                            throw new Error("Failed to update customer");
                        }
                        setLoading(false);
                    })
                    .catch(error => {
                        Swal.fire({
                            text: error.response?.data?.message || error.message || 'An error occurred',
                            icon: "error"
                        });
                        setLoading(false);
                    });
            } else {
                throw new Error("Failed to fetch customer data");
            }
        })
        .catch(error => {
            Swal.fire({
                text: error.response?.data?.message || error.message || 'An error occurred',
                icon: "error"
            });
            setLoading(false);
        });
};





    const toggleDiv = (divName) => {
       setActiveButton(activeButton === divName ? null : divName);
        switch (divName) {
            case 'settings':
                if (settingsVisible) return; 
                resetData();
                setSettingsVisible(!settingsVisible);
                setInqVisible(false);
                setCallVisible(false);
                setEmailVisible(false);
                setTextVisible(false);
                setPinVisible(false);
                setMeetVisible(false);
                setNoteVisible(false);
                setUploadVisible(false);
                setInqueryVisible(false);
                setIsSearch(false);
                break;
            // case 'inq':
            //     if (inqVisible) return; 
            //     resetData();
            //     setInqVisible(!inqVisible);
            //     setSettingsVisible(false);
            //     setCallVisible(false);
            //     setEmailVisible(false);
            //     setTextVisible(false);
            //     setPinVisible(false);
            //     setMeetVisible(false);
            //     setNoteVisible(false);
            //     setUploadVisible(false);
            //     setInqueryVisible(false);
            //     setIsSearch(false);
            //     break;
                case 'inq':
                    if (inqueryVisible) return; 
                    resetData();
                    setInqueryVisible(!inqueryVisible);
                    setSettingsVisible(false);
                    setCallVisible(false);
                    setEmailVisible(false);
                    setTextVisible(false);
                    setPinVisible(false);
                    setMeetVisible(false);
                    setNoteVisible(false);
                    setUploadVisible(false);
                    setIsSearch(false);
                    break;
                case 'call':
                    if (callVisible) return; 
                    setCallVisible(!callVisible);
                    setSettingsVisible(false);
                    setInqVisible(false);
                setEmailVisible(false);
                setTextVisible(false);
                setPinVisible(false);
                setMeetVisible(false);
                setNoteVisible(false);
                setUploadVisible(false);
                setInqueryVisible(false);
                setIsSearch(false);
                    break;
                    case 'email':
                        if (emailVisible) return; 
      
                        setSearchQuery("");
                        setTemplate_details([]);
                        setEmailErrorList([]);
                        resetEmail();
                        setEmailVisible(!emailVisible);
                        setSettingsVisible(false);
                        setInqVisible(false);
                        setCallVisible(false);
                        setTextVisible(false);
                        setPinVisible(false);
                        setMeetVisible(false);
                        setNoteVisible(false);
                        setUploadVisible(false);
                        setInqueryVisible(false);
                        setIsSearch(false);
                       
                        break;
                        case 'text':
                            if (textVisible) return; 
                            setTextVisible(!textVisible);
                            setSettingsVisible(false);
                            setInqVisible(false);
                            setCallVisible(false);
                            setEmailVisible(false);
                            setPinVisible(false);
                            setMeetVisible(false);
                            setNoteVisible(false);
                            setUploadVisible(false);
                            setInqueryVisible(false);
                            resetSms();
                            setErrorList([]);
                            setText_template_details([]);
                            setIsSearch(false);
                            break;
          case 'pin':
            if (pinVisible) return; 
            setPinVisible(!pinVisible);
            setSettingsVisible(false);
            setInqVisible(false);
            setCallVisible(false);
            setEmailVisible(false);
            setTextVisible(false);
            setMeetVisible(false);
            setNoteVisible(false);
            setUploadVisible(false);
            setInqueryVisible(false);
            setIsSearch(false);
            break;
          case 'meet':
            if (meetVisible) return; 
            setMeetVisible(!meetVisible);
            setSettingsVisible(false);
            setInqVisible(false);
            setCallVisible(false);
            setEmailVisible(false);
            setTextVisible(false);
            setPinVisible(false);
            setNoteVisible(false);
            setUploadVisible(false);
            setInqueryVisible(false);
            setMeetingErrorList([]);
            setIsSearch(false);
            break;
          case 'note':
            if (noteVisible) return; 
            setNoteVisible(!noteVisible);
            setSettingsVisible(false);
            setInqVisible(false);
            setCallVisible(false);
            setEmailVisible(false);
            setTextVisible(false);
            setPinVisible(false);
            setMeetVisible(false);
            setUploadVisible(false);
            setInqueryVisible(false);
            setNoteErrorList([]);
            setNote_template_details([]);
            setIsSearch(false);
            break;
            case 'upload':
                if (uploadVisible) return; 
                setUploadVisible(!uploadVisible);
                setSettingsVisible(false);
                setInqVisible(false);
                setCallVisible(false);
                setEmailVisible(false);
                setTextVisible(false);
                setPinVisible(false);
                setMeetVisible(false);
                setNoteVisible(false);
                setInqueryVisible(false);
                setUploadErrorList([]);
                setUpload_template_details([]);
                setIsSearch(false);
                break;
          default:
            break;
        }
      };

      function getDayName(dateString) {
        const date = new Date(dateString);
        const options = { weekday: 'short' };
        return date.toLocaleDateString('en-US', options);
    }


      function formatDate(dateString) {
        const date = new Date(dateString);
        const options = { month: 'short', day: 'numeric', year: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    }
    
    function formatTime(dateString) {
        const date = new Date(dateString);
        const options = { hour: 'numeric', minute: 'numeric', hour12: true };
        return date.toLocaleTimeString('en-US', options);
    }

    useEffect(() => { 
        let isMounted = true; // Track whether the component is mounted
        setIsLoading(true);
    
        Promise.all([
            getCustomerList(),
            getTeamList(),
            getEmailTemplateList(),
            getSmsTemplateList(),
            getNoteTemplateList(),
            getUploadTemplateList(),
            getPinTemplateList(),
            getInventoryList()
        ])
        .then(() => {
            if (isMounted) {
                setIsLoading(false);  // Loader stops only if the component is still mounted
            }
        })
        .catch((error) => {
            console.error("An error occurred", error);
            if (isMounted) {
                setIsLoading(false);  // Stop loader only if the component is still mounted
            }
        });
    
        return () => {
            isMounted = false; // Cleanup on component unmount
        };
    }, []);
    


useEffect(() => {
    let isMounted = true; // Track whether the component is mounted

    const loadUnlayerScript = () => {
        return new Promise((resolve, reject) => {
            if (document.querySelector('script[src="https://editor.unlayer.com/embed.js"]')) {
                resolve();
                return;
            }
            const script = document.createElement('script');
            script.src = 'https://editor.unlayer.com/embed.js';
            script.onload = resolve;
            script.onerror = reject;
            document.body.appendChild(script);
        });
    };

    const initializeEditor = () => {
        if (editorContainer.current) {
            if (isEditorInitialized.current) {
                window.unlayer.destroy(); // Cleanup the existing editor
                isEditorInitialized.current = false;
            }

            loadUnlayerScript()
                .then(() => {
                    if (editorContainer.current && isMounted) {
                        window.unlayer.init({
                            id: editorContainer.current.id,
                            displayMode: 'email',
                        });
                        isEditorInitialized.current = true;

                        // Load saved message
                        if (message) {
                            window.unlayer.loadDesign(JSON.parse(message));
                        } else {
                            window.unlayer.loadDesign({});
                        }
                    }
                })
                .catch((error) => {
                    if (isMounted) console.error('Failed to load Unlayer script:', error);
                });
        }
    };

    initializeEditor();

    return () => {
        isMounted = false; // Prevent state updates or initialization when unmounted
        if (isEditorInitialized.current) {
            window.unlayer.removeEventListener('design:changed');
            isEditorInitialized.current = false;
        }
    };
}, [emailVisible, showModal]);

  

function getCustomerList() {
    return axios.get(`${API_URL}/view-customer`)
        .then(res => {
          
            if (res.data.status === 200) {
                const customer = res.data.customer;
                setCustomer(res.data.customer); 
    
                
                const mergedArrayCustomer = res.data.customer.map(section => {
                    const inquiry = res.data.inquiry.filter(item => item.customer_id === section.id);
                    const emailLogs = res.data.emailLogs.filter(item => item.customer_id === section.id);
                    const noteLogs = res.data.noteLogs.filter(item => item.customer_id === section.id);
                    const meetLogs = res.data.meetLogs.filter(item => item.customer_id === section.id);
                    const uploadDocuments1 = res.data.uploadDocuments.filter(item => item.customer_id === section.id);
                    
                    const allLogs = [...inquiry, ...emailLogs, ...noteLogs, ...meetLogs, ...uploadDocuments1];
                    
                    let latestActivity = null;
                    if (allLogs.length > 0) {
                        allLogs.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                        latestActivity = allLogs[0];
                    }
                
                    return { ...section, inquiry, emailLogs, noteLogs, meetLogs, uploadDocuments1, latestActivity };
                });
           
                const customerWithMostRecentActivity = mergedArrayCustomer.reduce((mostRecent, customer) => {
                    if (customer.latestActivity && (!mostRecent.latestActivity || new Date(customer.latestActivity.created_at) > new Date(mostRecent.latestActivity.created_at))) {
                        return customer;
                    }
                    return mostRecent;
                }, { latestActivity: null });
                
                if (customerWithMostRecentActivity.latestActivity) {
                  getCustomerById(customerWithMostRecentActivity.id);
                  navigate(`/leads-details/${customerWithMostRecentActivity.id}`);
                } 
      

            }else if(res.data.status === 404){ 
                setIsLoading(false);
                // setCustomer([]);
            }
            // else {
            //     setIsLoading(false);
            //     throw new Error('Failed to fetch customer list');
            // }
        })
        .catch(error => {
            setIsLoading(false);
            // throw new Error('Error loading customer data:', error);
           // throw new Error(`Error loading customer data: ${error.message || error}`);
            
        });
      
}


    function getEmailTemplateList()
    {    
      axios.get(`${API_URL}/view-email-template`).then(res=>{
          if(res.data.status === 200){
              setEmail_template(res.data.emailTemplate);       
          }     
          else if(res.data.status === 404){
            setEmail_template([]);
             // setMessage(res.data.message);     
              }             
       });    
    }

    function getSmsTemplateList()
    {    
      axios.get(`${API_URL}/view-sms-template`).then(res=>{
          if(res.data.status === 200){
              setSms_template(res.data.smsTemplate);       
          }     
          else if(res.data.status === 404){
            setSms_template([]);
             // setMessage(res.data.message);     
              }             
       }); 
       
       
    }

    function getNoteTemplateList()
    {    
      axios.get(`${API_URL}/view-note-template`).then(res=>{
          if(res.data.status === 200){
              setNote_template(res.data.noteTemplate);       
          }     
          else if(res.data.status === 404){
            setNote_template([]);
             // setMessage(res.data.message);     
              }             
       });    
    }

    function getTeamList()
    {    
     
    axios.get(`${API_URL}/view-team-members`).then(res=>{
          if(res.data.status === 200){
              setTeam(res.data.team);       
          }     
          else if(res.data.status === 404){
            setTeam([]);
             // setMessage(res.data.message);     
              }             
       });    
    }

    function getUploadTemplateList()
    {    
      axios.get(`${API_URL}/view-upload-template`).then(res=>{
          if(res.data.status === 200){
              setUpload_template(res.data.uploadTemplate);       
          }     
          else if(res.data.status === 404){
            setUpload_template([]);
             // setMessage(res.data.message);     
              }             
       });    
    }

    function getPinTemplateList()
    {    
      axios.get(`${API_URL}/view-pin-template`).then(res=>{
          if(res.data.status === 200){
              setPin_template(res.data.pinTemplate);       
          }     
          else if(res.data.status === 404){
            setPin_template([]);
             // setMessage(res.data.message);     
              }             
       });    
    }
   


    function getCustomerById(id){
        resetVehicleAnalyse();
   
          axios.get(`${API_URL}/view-customer-by-id/`+id).then(res=>{
            const { customer, inquiry, emailLogs, smsLogs, noteLogs, meetLogs, uploadDocuments } = res.data;

             setCustomerById(res.data.customer);
             setEmail(res.data.customer[0].email);
             setFirst_name(res.data.customer[0].first_name);
             setPhone_no(res.data.customer[0].phone_no);
     
             let assign_to = [];
             if(res.data.customer[0].assign_to) {
            try {
                assign_to = JSON.parse(res.data.customer[0].assign_to);
        
                // console.log(assign_to);
            } catch (error) {
                console.error("Error parsing assign_to:", error);
            }
           }
             setTeam_select(assign_to);
             setInquiryById(res.data.inquiry);
             setEmailLogById(res.data.emailLogs);
             setSmsLogById(res.data.smsLogs);
             setNoteLogById(res.data.noteLogs);
             setMeetLogById(res.data.meetLogs);
             setUploadLogById(res.data.uploadDocuments);


             const allLogs = [...inquiry, ...emailLogs, ...smsLogs, ...noteLogs, ...meetLogs, ...uploadDocuments];
             if (allLogs.length > 0) {
             allLogs.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
             const latestActivity = allLogs[0];
     
             let defaultTab = '';
             if (inquiry.includes(latestActivity)) {
                defaultTab = 'inquiry';
             } else if (emailLogs.includes(latestActivity)) {
                 defaultTab = 'email';
             } else if (smsLogs.includes(latestActivity)) {
                 defaultTab = 'sms';
             } else if (noteLogs.includes(latestActivity)) {
                 defaultTab = 'note';
             } else if (meetLogs.includes(latestActivity)) {
                 defaultTab = 'meet';
             } else if (uploadDocuments.includes(latestActivity)) {
                 defaultTab = 'upload';
             }

             setActiveButton(defaultTab);
        setIsSearch(false);
        setSettingsVisible(defaultTab === 'settings');
        setInqueryVisible(defaultTab === 'inquiry');
        setInqVisible(defaultTab === 'inq');
        setCallVisible(defaultTab === 'call');
        setEmailVisible(defaultTab === 'email');
        setTextVisible(defaultTab === 'sms');
        setPinVisible(defaultTab === 'pin');
        setMeetVisible(defaultTab === 'meet');
        setNoteVisible(defaultTab === 'note');
        setUploadVisible(defaultTab === 'upload');
            }
            else {
                // If no activity logs are present, default to the inquiry tab
                setActiveButton(' ');
                setIsSearch(false);
                setInqueryVisible(true);
                setSettingsVisible(false);
                setInqVisible(false);
                setCallVisible(false);
                setEmailVisible(false);
                setTextVisible(false);
                setPinVisible(false);
                setMeetVisible(false);
                setNoteVisible(false);
                setUploadVisible(false);
            }
        setIsUploadImageLoading(true);
        setIsPhotoLoading(true);
           setIsLoading(false);
            });          
      
        }

    function getEmaillogbyId(id){
        axios.get(`${API_URL}/view-customer-by-id/`+id).then(res=>{
            setCustomerById(res.data.customer);
            setEmail(res.data.customer[0].email);
            setInquiryById(res.data.inquiry);
            setEmailLogById(res.data.emailLogs);
            setIsSearch(false);
            setInqueryVisible(false);
            setSettingsVisible(false);
            setInqVisible(false);
            setCallVisible(false);
            setEmailVisible(true);
            setTextVisible(false);
            setPinVisible(false);
            setMeetVisible(false);
            setNoteVisible(false);
            setUploadVisible(false);
        });  
 
    }
    

    function getSmslogbyId(id){

        axios.get(`${API_URL}/view-customer-by-id/`+id).then(res=>{
            setCustomerById(res.data.customer);
            setSmsLogById(res.data.smsLogs);
            setIsSearch(false);
            setInqueryVisible(false);
            setInqVisible(false);
            setSettingsVisible(false);
            setCallVisible(false);
            setEmailVisible(false);
            setTextVisible(true);
            setPinVisible(false);
            setMeetVisible(false);
            setNoteVisible(false);
            setUploadVisible(false);

        });  

    }

    function getMeetlogbyId(id){
        axios.get(`${API_URL}/view-customer-by-id/`+id).then(res=>{
            setCustomerById(res.data.customer);
            setMeetLogById(res.data.meetLogs);
            setIsSearch(false);
            setInqueryVisible(false);
            setSettingsVisible(false);
            setInqVisible(false);
            setCallVisible(false);
            setEmailVisible(false);
            setTextVisible(false);
            setPinVisible(false);
            setMeetVisible(true);
            setNoteVisible(false);
            setUploadVisible(false);
        });  
 
    }
     
    function getNotelogbyId(id){
        axios.get(`${API_URL}/view-customer-by-id/`+id).then(res=>{
            setCustomerById(res.data.customer);
            setNoteLogById(res.data.noteLogs);
            setIsSearch(false);
            setInqueryVisible(false);
            setSettingsVisible(false);
            setInqVisible(false);
            setCallVisible(false);
            setEmailVisible(false);
            setTextVisible(false);
            setPinVisible(false);
            setMeetVisible(false);
            setNoteVisible(true);
            setUploadVisible(false);
        });  
 
    }

    function getUploadlogbyId(id){
        axios.get(`${API_URL}/view-customer-by-id/`+id).then(res=>{
            setCustomerById(res.data.customer);
            setUploadLogById(res.data.uploadDocuments);
            setIsSearch(false);
            setInqueryVisible(false);
            setInqVisible(false);
            setSettingsVisible(false);
            setCallVisible(false);
            setEmailVisible(false);
            setTextVisible(false);
            setPinVisible(false);
            setMeetVisible(false);
            setNoteVisible(false);
            setUploadVisible(true);
        });  
 
    }
        

    function getInventoryList(){
 
        axios.get(`${API_URL}/view-inventory`).then(res=>{
            if(res.data.status === 200){
              const inventory = res.data.inventorySection;
                setInventory(res.data.inventorySection);
                //setInventory(res.data.localInventory);
                

                const mergedArray =    res.data.inventorySection.map(section => {
                    const coverImage = res.data.inventoryCoverImages.find(image => image.inventory_section_id === section.id);
                    const price = res.data.inventorySection3.find(data => data.inventory_section_id === section.id);
                    const inventoryImages = res.data.inventoryImages.find(image => image.inventory_section_id === section.id);
                    // const count = (inventoryImages.filepath_compressed.split(',').length) + (coverImage.filepath.split(',').length)
                    return { ...section, coverImage, price };    
                 
                  })

                  setMergedArray(res.data.inventorySection.map(section => {
                    const coverImage = res.data.inventoryCoverImages.find(image => image.inventory_section_id === section.id);
                    const price = res.data.inventorySection3.find(data => data.inventory_section_id === section.id);
                    const inventoryImages = res.data.inventoryImages.find(image => image.inventory_section_id === section.id);
                    // const count = (inventoryImages.filepath_compressed.split(',').length) + (coverImage.filepath.split(',').length)
                 
                    return { ...section, coverImage, price, inventoryImages };    
                 
                  }))   

                const selectedInventory = inventory.filter((row) => row.global_inventory === "false");
                const uniqueMake = [...new Set(selectedInventory.map(row => row.brand.toLowerCase()))]
                .map(make => make.charAt(0).toUpperCase() + make.slice(1));
                setMakeOptions(uniqueMake ? uniqueMake : []);

                const filteredInventory = mergedArray.filter((item) => item.global_inventory === "false");
                setFilteredInventory(filteredInventory);
               
 
            }
            else if(res.data.status === 404){
              //  setMessage(res.data.message);
                }
                
           
         });
           
        }

    
    const handleInventoryTypeChange  = (selectedOptions) => {
        setSelectedInventoryType(selectedOptions.value);
        if (selectedOptions.value === 'all') {
            const uniqueMake = [...new Set(inventory.map(row => row.brand.toLowerCase()))]
            .map(make => make.charAt(0).toUpperCase() + make.slice(1));       
            setDisableMakeOptions(false); 
            setModelOptions([]);
            setMakeOptions(uniqueMake ? uniqueMake : []);
            setSelectedMakes([]);
            setSelectedModels([]);

          } else {
            const selectedInventory = inventory.filter((row) => row.global_inventory === selectedOptions.value);

            const uniqueMake = [...new Set(selectedInventory.map(row => row.brand.toLowerCase()))]
      .map(make => make.charAt(0).toUpperCase() + make.slice(1));
      
         
           setDisableMakeOptions(false);
           setModelOptions([]);
             setMakeOptions(uniqueMake ? uniqueMake : []);
             setSelectedMakes([]);
             setSelectedModels([]);
         
          }
        
};
   

const handleMakeChange = (selectedOptions) => {
    const selectedMakeValues = selectedOptions.map(option => option.value);
    setSelectedMakes(selectedMakeValues);
    const filteredModels = filteredInventory.filter(row => selectedMakeValues.includes(row.brand))
      .map(row => row.model_name.toLowerCase());
    const uniqueModels = [...new Set(filteredModels)];
    const formattedUniqueModels = uniqueModels.map(model => model.charAt(0).toUpperCase() + model.slice(1));
    setModelOptions(formattedUniqueModels);


//   // Filter body styles based on selected make
//   const filteredBodyStyles = filteredInventory
//     .filter(row => selectedMakeValues.includes(row.brand))
//     .map(row => row.body_type.toLowerCase());
//   const uniqueBodyStyles = [...new Set(filteredBodyStyles)];
//   setBodyStyleOptions(uniqueBodyStyles);

//   // Filter engines based on selected make
//   const filteredEngines = filteredInventory
//     .filter(row => selectedMakeValues.includes(row.brand))
//     .map(row => row.engine_type.toLowerCase());
//   const uniqueEngines = [...new Set(filteredEngines)];
//   setEngineOptions(uniqueEngines);

//   // Filter exterior colors based on selected make
//   const filteredExteriorColors = filteredInventory
//     .filter(row => selectedMakeValues.includes(row.brand))
//     .map(row => row.exterior_color.toLowerCase());
//   const uniqueExteriorColors = [...new Set(filteredExteriorColors)];
//   setExteriorColorOptions(uniqueExteriorColors);

//   // Filter transmissions based on selected make
//   const filteredTransmissions = filteredInventory
//     .filter(row => selectedMakeValues.includes(row.brand))
//     .map(row => row.transmission.toLowerCase());
//   const uniqueTransmissions = [...new Set(filteredTransmissions)];
//   setTransmissionOptions(uniqueTransmissions);

//   const filteredDriveType = filteredInventory
//   .filter(row => selectedMakeValues.includes(row.brand))
//   .map(row => row.drive_type.toLowerCase());
// const uniqueDriveType = [...new Set(filteredDriveType)];
// setDriveTypeOptions(uniqueDriveType);


// const filteredDoors = filteredInventory
// .filter(row => selectedMakeValues.includes(row.brand))
// .map(row => row.door.toLowerCase());
// const uniqueDoors = [...new Set(filteredDoors)];
// setDoorsOptions(uniqueDoors);

// const filteredStockNo = filteredInventory
// .filter(row => selectedMakeValues.includes(row.brand))
// .map(row => row.stock_no);
// const uniqueStockNo = [...new Set(filteredStockNo)];
// setStockNoOptions(uniqueStockNo);

    
  };

  const handleModelChange = (selectedOptions) => {
    const selectedModelValues = selectedOptions.map(option => option.value);
    setSelectedModels(selectedModelValues);
  
  };

  const handleBodyStyleChange = (selectedOptions) => {
    const selectedBodyStyleValues = selectedOptions.map(option => option.value);
    setSelectedBodyStyles(selectedBodyStyleValues);
  

  };


  const handleEngineChange = (selectedOptions) => {
    const selectedEngineValues = selectedOptions.map(option => option.value);
    setSelectedEngines(selectedEngineValues);
  };

  const handleExteriorColorChange = (selectedOptions) => {
    const selectedExteriorColorValues = selectedOptions.map(option => option.value);
    setSelectedExteriorColors(selectedExteriorColorValues);
   
  };

  const handleTransmissionChange = (selectedOptions) => {
    const selectedTransmissionValues = selectedOptions.map(option => option.value);
    setSelectedTransmissions(selectedTransmissionValues);
  };

  const handleDriveTypeChange = (selectedOptions) => {
    const selectedDriveTypeValues = selectedOptions.map(option => option.value);
    setSelectedDriveType(selectedDriveTypeValues);
  };

  const handleDoorChange = (selectedOptions) => {
    const selectedDoorValues = selectedOptions.map(option => option.value);
    setSelectedDoors(selectedDoorValues);
  };

  const handleStockNoChange = (selectedOptions) => {
    const selectedStockNo = selectedOptions.map(option => option.value);
    setSelectedStockNo(selectedStockNo);
  };


  const search = (event) => {
    event.preventDefault();
    setLoading(true);

    let hasError = false;

    // Validate the make field
    if (selectedMakes.length === 0) {
        setMakeError("The make field is required");
        hasError = true;
      
    } else {
        setMakeError("");
    }

    // Validate the model field
    // if (selectedModels.length === 0) {
    //     setModelError("The model field is required");
    //     hasError = true;
    // } else {
    //     setModelError("");
    // }

    // If there are errors, stop further execution
    if (hasError) {
        setLoading(false);
        return;
    }


    setFilteredResults([]);
    setIsSearch(true);
    setInqueryVisible(false);

    //   if (selectedInventoryType === "") {
    //     document.getElementById("errorMessage").style.display = "block";
    //   }
    //   else{
    //     document.getElementById("errorMessage").style.display = "none";
    //   }
   
 // console.log(mergedArray);
  const filteredInventory = mergedArray.filter((item) => {
        return (
          (selectedInventoryType === 'all' || item.global_inventory === selectedInventoryType) &&
          (!selectedMakes.length || selectedMakes.includes(item.brand) || 
          (selectedMakes.includes(item.brand) && selectedModels.includes(item.model_name)) )&&
          (!minPrice || item.price.sale_price >= minPrice) &&
          (!maxPrice || item.price.sale_price <= maxPrice) &&
          (!minKilometer || item.odometer >= minKilometer) &&
          (!maxKilometer || item.odometer <= maxKilometer)
        );
      })
      
    setFilteredInventory(filteredInventory);
    //console.log(filteredInventory);
     
    var myModalEl = document.getElementById('exampleModalToggle');
    myModalEl.classList.remove('show');

    // Remove the modal-backdrop element
    var backdropEl = document.getElementsByClassName('modal-backdrop')[0];
    if (backdropEl) {
        backdropEl.remove();
    }
    
    // Reset the modal state
    myModalEl.style.display = 'none';
    myModalEl.setAttribute('aria-hidden', 'true');
    myModalEl.setAttribute('aria-modal', 'false');
    myModalEl.removeAttribute('role');
    myModalEl.removeAttribute('aria-labelledby');
    myModalEl.removeAttribute('tabindex');
    
    setLoading(false);
  }


//   useEffect(() => {
//    // const count = filteredResult.length;
//    //setRecordCount(count);
//  }, [filteredInventory]);  

 function resetVehicleAnalyse(){
    setSelectedMakes([]);
setSelectedModels([]);
    setMinPrice("");
    setMaxPrice("");
setMinKilometer("");
setMaxKilometer("");
setMakeError("");

 } const handleSelectAllTeam = (event) => {
    if (event.target.checked) {
      const allTeamIds = team.map(member => member.id.toString()); // Convert all ids to strings for comparison
      setTeam_select(allTeamIds);
    } else {
      setTeam_select([]);
    }
  };
 

 const handleSelectAll = (e) => {
    const checked = e.target.checked;
    const allItemIds = filteredInventory.map((item) => item.id);
    if (checked) {
      setSelectedRows(allItemIds);
   //console.log(allItemIds);
    } else {
      setSelectedRows([]);
    }
  };

  const handleRowSelect = (e, id) => {
      const checked = e.target.checked;
      let updatedSelectedRows;
    
      if (checked) {
        updatedSelectedRows = [...selectedRows, id];
      } else {
        updatedSelectedRows = selectedRows.filter((row) => row !== id);
      }
    
      setSelectedRows(updatedSelectedRows);
  
      const allRowsSelected = filteredInventory.every((item) => updatedSelectedRows.includes(item.id));
  
      selectAllCheckboxRef.current.checked = allRowsSelected; // Assuming you have a ref to the "Select All" checkbox
    };
  

  const handleGenerate = () => {
   
//  console.log(customerById[0].phone_no)
     if(selectedRows.length){
        setLoading(true);
        const queryParams = selectedRows.join(",");
        const makesQueryParam = selectedMakes && selectedMakes.length > 0 ? `selectedMakes=${selectedMakes.join(",")}` : "";
        const modelsQueryParam = selectedModels && selectedModels.length > 0 ? `selectedModels=${selectedModels.join(",")}` : "";
        const queryString = [queryParams, makesQueryParam, modelsQueryParam].filter(queryParam => queryParam !== "").join("&");
        window.location.href = `/vehicle-list?selectedRows=${queryString}&email=${customerById[0].email}&phoneNo=${encodeURIComponent(customerById[0].phone_no)}`;

       //navigate(`/vehicle-list?selectedRows=${queryString}&email=${customerById[0].email}&phoneNo=${encodeURIComponent(customerById[0].phone_no)}`);

    }
     else
     alert("Please select a vehicle");

   
};

async function saveInquiry(event)
  {
    event.preventDefault();
    setLoading(true);
   
    const formData = new FormData();

    formData.append('id', id)
    formData.append('vehicle_name', vehicle_name)
    formData.append('selectedMakes', selectedMakes)
    formData.append('selectedModels', selectedModels)
    formData.append('minPrice', minPrice)
    formData.append('maxPrice', maxPrice)
    formData.append('minKilometer', minKilometer)
    formData.append('maxKilometer', maxKilometer)
    formData.append('selectedBodyStyles', selectedBodyStyles)
    formData.append('selectedEngines', selectedEngines)
    formData.append('selectedExteriorColors', selectedExteriorColors)
    formData.append('selectedTransmissions', selectedTransmissions)
    formData.append('selectedDriveType', selectedDriveType)
    formData.append('selectedDoors', selectedDoors)
    formData.append('selectedStockNo', selectedStockNo)
    formData.append('description', description)
 
   await axios.post(`${API_URL}/save-inquiry`, formData).then(res =>{
         
    Swal.fire({
      icon:"success",
      text:res.data.message
    }).then(function(){    
        setLoading(false);     
         resetData();
         setErrorList([]);
         getCustomerById(id);
         
  });
  }).catch(function(error) {
    setLoading(false);   
    // if(error.response.status===422){         
   setErrorList(error.response.data.validate_err);
    // }
    // else{
      Swal.fire({
        text:error.response.data.message,
        icon:"error"
      })
    // }
  })
}

const resetData = () => {
   setVehicle_name("");
   setSelectedMakes([]);
   setSelectedModels([]);
   setMinPrice("");
   setMaxPrice("");
   setMinKilometer("");
   setMaxKilometer("");
   setSelectedBodyStyles([]);
   setSelectedEngines([]);
   setSelectedExteriorColors([]);
   setSelectedTransmissions([]);
   setSelectedDriveType([]);
   setSelectedDoors([]);
   setDescription("");
   
    };

    const resetEmail = () => {
        setTemplate([]);
        setSubject("");
        setMessage("");
        setImages([]);
        setVideos([]);
        setPreviews([]);
        setPreviewsVideo([]);
        setSuccessMessage("");
        setLoading(false);
         };

         const resetMeeting = () => {
            setTitle("");
            setDate("");
            setTime("");
             };
    
             const resetNote = () => {
                setNote_template_select("");
                setNote_subject("");
                setNote_message("");
          
                 };

                 const resetUpload = () => {
                    setUpload_template_select("");
                    setUpload_subject("");
                    setUpload_message("");
                    setImages([]);
                    setPreviews([]);
                     };

         const getFileType = (url) => {
            const extension = url.split('.').pop().toLowerCase();
            switch (extension) {
                case 'jpg':
                case 'jpeg':
                case 'png':
                    case 'webp':
                    return 'image';
                case 'pdf':
                    return 'pdf';
                case 'mp4':
                case 'avi':
                case 'mov':
                    return 'video';
                // Add more file types as needed
                default:
                    return 'unknown';
            }
        };
   
    const handleTemplateChange  = (event) => {
        setTemplate(event.target.value);
        if(event.target.value){
            const template_details = email_template.find(item=> item.template_name === event.target.value);
            setTemplate_details(template_details);
            setSubject(template_details.subject);
            // setMessage(template_details.message);
            const parsedMessage = template_details.message ? JSON.parse(template_details.message) : {};
            setMessage(parsedMessage);

          
    // Re-initialize Unlayer if it's not initialized
    if (window.unlayer && !window.unlayerInitialized) {
         const unlayerInstance = window.unlayer;
  
    //     // Initialize Unlayer
    //     unlayerInstance.init({
    //       id: 'editor-container',
    //       displayMode: 'email',
    //       tools: {
    //         text: {
    //           properties: {
    //             textAlign: false,
    //             lineHeight: false,
    //           },
    //         },
    //         image: {
    //           properties: {
    //             upload: true,
    //           },
    //         },
    //       },
    //     });
  
    //      window.unlayerInitialized = true;
  
    //     // Load existing design into the editor
        if (parsedMessage) {
          unlayerInstance.loadDesign(parsedMessage);
        }
  
        // Add event listener for design updates
        // const onDesignUpdated = (data) => {
        //   setMessage((prev) => ({
        //     ...prev,
        //     message: data,
        //   }));
        // };
  
    //     unlayerInstance.addEventListener('design:updated', onDesignUpdated);
  
    //     // Cleanup event listener on unmount
        // return () => {
        //   //unlayerInstance.removeEventListener('design:updated', onDesignUpdated);
        //   window.unlayerInitialized = false;
        // };
                
           }

           
            if (template_details.image_path) {

                const filepathArray = template_details.image_path.split(',');
                const imageUrlArray = filepathArray.map(filepath => `${LARAVEL_API_URL}/${filepath}`);
                setPreviews(imageUrlArray);
    
                const initialPreviewsPromises = imageUrlArray.map(async filepath => {
                    const filename = filepath.substring(filepath.lastIndexOf('/') + 1); // Extract filename from URL
                    const fileType = filename.split('.').pop().toLowerCase(); // Extract file extension and convert to lowercase
                
                    if (fileType === 'pdf') {
                        // For PDF files
                        try {
                            const response = await fetch(filepath);
                            const blob = await response.blob();
                            return new File([blob], filename, { type: 'application/pdf' });
                        } catch (error) {
                            console.error('Error fetching PDF file:', error);
                            return null;
                        }
                    } else if (['mp4', 'webm', 'ogg'].includes(fileType)) {
                        try {
                            const response = await fetch(filepath);
                            const blob = await response.blob();
                            return new File([blob], filename, { type: `video/${fileType}` });
                        } catch (error) {
                            console.error('Error fetching video:', error);
                            return null;
                        }
                    } else {
                        // For image files
                        try {
                            const response = await fetch(filepath);
                            const blob = await response.blob();
                            return new File([blob], filename, { type: `image/${fileType}` });
                        } catch (error) {
                            console.error('Error fetching image:', error);
                            return null;
                        }
                    }
                });
                
                Promise.all(initialPreviewsPromises)
                    .then(initialPreviews => {
                        // Filter out null values (in case of error)
                        const filteredPreviews = initialPreviews.filter(preview => preview !== null);
                        setImages(filteredPreviews);
                      //  console.log(filteredPreviews)
                    })
                    .catch(error => {
                        console.error('Error creating initial previews:', error);
                        setImages([]); // Set empty array in case of error
                    });
                
                const updatedPreviews = imageUrlArray.map(url => {
                    const fileType = getFileType(url);
                    return { type: fileType, url: url };
                });
                setPreviews(updatedPreviews);
            } 
            // if (template_details.video_path) {
            //     const filepathArray = template_details.video_path.split(',');
            //     const videoUrlArray = filepathArray.map(filepath => `${LARAVEL_API_URL}/${filepath}`);
            //     setPreviewsVideo(videoUrlArray);
    
            //     // Set videos based on preview videos
            //     const videoFiles = videoUrlArray.map(async url => {
            //         const res = await fetch(url);
            //         const blob = await res.blob();
    
            //         // Extract filename from URL
            //         const filename = url.substring(url.lastIndexOf('/') + 1);
    
            //         return new File([blob], filename, { type: "video/mp4" });
            //     });
            //     Promise.all(videoFiles).then(videos => {
            //         setVideos(videos);
            //     });
            // } else {
            //     setPreviewsVideo([]);
            //     setVideos([]);
            // }
        }
        else
        {   setTemplate(""); 
            setTemplate_details([]);
            setSubject("");
            setMessage("");
            setPreviews([]);
            setImages([]);

         
                if (window.unlayer && !window.unlayerInitialized) {
                    window.unlayer.loadDesign({ body: { rows: [] } }); // Explicitly clear the design
                }
                
            }
    
        };

        const openSearchPopup = (item) => {
            //setSelectedInquiry(item);
         $('#exampleModalToggle').modal('show');
          };

        const sendEmail =  async (e) => {
            e.preventDefault();
            setLoading(true);
            try {
            let emailArray;
            emailArray = email.split(",");
          
                // const formattedMessage = (template_details?.message ?? '').replace(/\n/g, '<br>');
                    
            // const messageWithLogo = `<img src="https://www.attodesk.com/Auttodesk-Black.png" alt="Attodesk Logo Big" width="140" /><br><br>${formattedMessage}`;
            
            const formData = new FormData();

            formData.append("customer_id", id);
            formData.append("to", emailArray);
            formData.append("subject", subject);
            if (window.unlayer) {
                const htmlOutput = await new Promise((resolve) => {
                    window.unlayer.exportHtml(({ html }) => {
                        resolve(html); // Get rendered HTML
                    });
                });

                const personalizedHtml = htmlOutput.replace(/\[First Name\]/g, first_name);
          
                // Append the design JSON as the message
                formData.append("message", personalizedHtml);
            }

            // formData.append("message", message.replace(/\n/g, '<br>'));
            // if(template_details.message){
            //     formData.append("message", formattedMessage);
            // }
            // else{
            //     formData.append("message", message);
            // }
           
          
            images.forEach((image, index) => {
              formData.append(`image[${index}]`, image);
            });

           
          
            // videos.forEach((video, index) => {
            //   formData.append(`video[${index}]`, video);
            // });
          
            const response = await axios.post(`${API_URL}/send-email-brevo`, formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              });
          
              // Show success message and reset states
              showToast("Email sent successfully!");
              setLoading(false);
              setShowModal(false);
              setTemplate_details([]);
              resetEmail();
              getEmaillogbyId(id);
              setEmailErrorList([]);
            } catch (error) {
              // Handle errors
              setLoading(false);
              if (error.response?.data?.validate_err) {
                setEmailErrorList(error.response.data.validate_err);
              } else {
                console.error("Error sending email:", error);
              }
            }
          };

          const sendSMS = async () => {
            setLoading(true);
          
            const formData = new FormData();
            formData.append("customer_id", id);
            formData.append("phone_no", phone_no);
            formData.append("message", text_message);
           
              const response = await  axios.post(`${API_URL}/send-sms`, formData) .then(() => {
            //   console.log('SMS sent successfully:', response.data);
              showToast("SMS sent successfully!"); 
              setText_template_details([]);
              resetSms();
              getSmslogbyId(id);
              setErrorList([]);
              setLoading(false);
            
            })
            .catch((error) => {
                setLoading(false);
              //console.error('Failed to send SMS:', error);      
            //   setError(error.response.data.message || 'Failed to send SMS');
            setErrorList(error.response.data.validate_err);
        
            // Swal.fire({
            //     text:error.response.data.message,error_list
            //     icon:"error"
            //   })
        
            //   showToastFail("Failed to send SMS");
            }) 
          };


          const makeCall = async () => {
            setLoading(true);
            try {
              
                const response = await axios.post(`${API_URL}/make-call`, {
                    recipient_number: '+94763125620' // Replace with recipient number
                });
              
            } catch (error) {
                console.error(error);
            }
            setLoading(false);
        };

        const MeetingFormatDate = (dateString) => {
            const date = new Date(dateString);
            const options = { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric' };
            return date.toLocaleDateString('en-US', options);
        };
        
        const MeetingFormatTime = (dateTimeString) => {
            const date = new Date(dateTimeString);
            let hours = date.getHours();
            let minutes = date.getMinutes();
            const ampm = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12 || 12;
            minutes = minutes < 10 ? '0' + minutes : minutes;
            return `${hours}:${minutes} ${ampm}`;
        };

        const sendMeetingEmail = (e) => {
            e.preventDefault();
            setLoading(true);
                 
            // Construct the message body
            const messageBody = `
            <div class="header">
    <img src="https://www.attodesk.com/Auttodesk-Black.png" alt="Attodesk Logo Big" width="140" />
   </div>

    <p><h2>Your appointment for ${title} has been confirmed, ${first_name}!<h2> </p>
    <p><strong>Date:</strong> ${formattedDateString}</p>
    <p><strong>Time:</strong> ${time}</p>
    <p><strong>Location:</strong> 415 The West Plaza, Ste. 110 Toronto, Ontario A9C 5J1</p><br>

 
<p>Regards,<br>Attodesk team,<br>Phone No: +1 (647) 555-5678</p>
 
            `;
        
            const formData = new FormData();
            formData.append("customer_id", id);
            formData.append("to", email);
            formData.append("title", title);
            formData.append("date", date);
            formData.append("time", time);

            formData.append("subject", "Appointment Confirmation");
            formData.append("message", messageBody); 
     
        
            axios.post(`${API_URL}/save-meeting`, formData)
            .then(() => {
                showToast("Email sent successfully!"); 
                setLoading(false);
                // setTemplate_details([]);
                resetMeeting();
                getMeetlogbyId(id);
                setMeetingErrorList([]);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                 setMeetingErrorList(error.response.data.validate_err);
            });
        };

        const sendNoteEmail = (e) => {
            e.preventDefault();
            setLoading(true);
        
                // const formattedMessage = (template_details?.message ?? '').replace(/\n/g, '<br>');
                    
            // const messageWithLogo = `<img src="https://www.attodesk.com/Auttodesk-Black.png" alt="Attodesk Logo Big" width="140" /><br><br>${formattedMessage}`;
            
            const formData = new FormData();
            formData.append("customer_id", id);
            formData.append("to", email);
            formData.append("note_subject", note_subject);
            formData.append("note_message", note_message.replace(/\n/g, '<br>'));
         
         
            axios.post(`${API_URL}/send-note-email`, formData)
              .then(() => {
               
               showToast("Email sent successfully!"); 
                setLoading(false);
                setNote_template_details([]);
                resetNote();
                getNotelogbyId(id);
                setNoteErrorList([]);
               
              })
              .catch((error) => {
                setLoading(false);
                setNoteErrorList(error.response.data.validate_err);
              //  console.error("Error sending email:", error.response.data);
                // Swal.fire({
                //     text:error.response.data.message,
                //     icon:"error"
                //   })
              });
          };

          const uploadDocuments = (e) => {
            e.preventDefault();
            setLoading(true);
          
            const formData = new FormData();
            formData.append("customer_id", id);
            formData.append("upload_subject", upload_subject);
            formData.append("upload_message", upload_message.replace(/\n/g, '<br>'));
           
            images.forEach((image, index) => {
              formData.append(`image[${index}]`, image);
            });
          
            axios.post(`${API_URL}/upload-documents-by-id`, formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
                
              }
              )
              .then(() => {
              
               showToast("Document uploaded!"); 
               
                setLoading(false);
                setUpload_template_details([]);
                resetUpload();
                getUploadlogbyId(id);
                setUploadErrorList([]);
               
              })
              .catch((error) => {
                setLoading(false);
                setUploadErrorList(error.response.data.validate_err);
                //console.error("Error sending email:", error.response.data);
                Swal.fire({
                    text:error.response.data.message,
                    icon:"error"
                  })

              });
          };
             
          


          function onSelectImages(event){
            const selectedImages = Array.from(event.target.files);

                const validImages = selectedImages.filter(image => image.size <= 15 * 1024 * 1024); // 15MB 
                if (validImages.length !== selectedImages.length) {
                  Swal.fire({
                    text: "Image file size should be less than or equal to 15MB.",
                    icon: "error"
                  });
                }       
                setImages([...images, ...validImages]);       
            
                //  console.log([...images, ...validImages]);
               // const filePreviews = validImages.map((image) => URL.createObjectURL(image));
        
                const filePreviews = validImages.map(file => {
                if (file.type === 'application/pdf') {
                    return { type: 'pdf', name: file.name , url: URL.createObjectURL(file)};
                } else if (file.type === 'video/mp4') {
                  return { type: 'video', name: file.name, url: URL.createObjectURL(file) };
                 } else {
                    return { type: 'image', name: file.name ,url: URL.createObjectURL(file) };
                }
            });
        
                setPreviews([...previews, ...filePreviews]);
                

            
          }
    
          function onSelectVideos(event){
    
            const selectedVideos = Array.from(event.target.files);
    
            // Check the file size for each selected video
            const validVideos = selectedVideos.filter(video => video.size <= 10 * 1024 * 1024); // 25MB
          
            if (validVideos.length !== selectedVideos.length) {
              // Show the validation error message
              Swal.fire({
                text: "Video file size should be less than or equal to 10MB.",
                icon: "error"
              });
            }
          
            setVideos(validVideos);
          
            const videoPreviews = validVideos.map(video => URL.createObjectURL(video));
            setPreviewsVideo(videoPreviews);
          }
    
          
          function deleteImage(index){
            const updatedFiles = [...images];
          updatedFiles.splice(index, 1);
          setImages(updatedFiles);
          setPreviews(previews.filter((_, i) => i !== index));
         
         }
    

        
          
          
        //  function deleteVideo(index){
        //     const updatedVideos = [...videos];
        //     updatedVideos.splice(index, 1);
        //   setVideos(updatedVideos);
        //   setPreviewsVideo(previewsVideo.filter((_, i) => i !== index));
         
        //  }

        const handleImageClick =  (index) => {
            Swal.fire({
                imageUrl: previews[index].url,
                imageAlt: `Preview ${index}`,
                showCloseButton: true,
                showConfirmButton: false,
                imageWidth: "100%",
                imageHeight: 'auto',
            });
          }
        
          const handlePdfClick = (index) => {
            const pdfUrl = previews[index].url;
            window.open(pdfUrl, '_blank');
        }
        
        const handleVideoClick = (index) => {
          const videoUrl = previews[index].url;
          
          Swal.fire({
            html: `<div style="overflow: hidden;">
                      <video width="100%" height="100%" controls style="margin-top: 15px;"> <!-- Adjust margin-right to accommodate scrollbar -->
                          <source src="${videoUrl}" type="video/mp4">
                          Your browser does not support the video tag.
                      </video>
                  </div>`,
            showCloseButton: true,
            showConfirmButton: false,
            scrollbarPadding: false 
          });
        };


        
        const resetSms = () => {
            setText_template([]);
            setText_message("");
            setLoading(false);
             };

             const handleTextTemplateChange  = (event) => {
                setText_template(event.target.value);
                if(event.target.value){
                    const text_template_details = sms_template.find(item=> item.template_name === event.target.value);
                    setText_template_details(text_template_details);
                    setText_message(text_template_details.message);
                 
                }
                else
                {   setText_template(""); 
                    setText_template_details([]);
                    setText_message("");
                }
                };

                const handleNoteTemplateChange  = (event) => {
                    setNote_template_select(event.target.value);
                    if(event.target.value){
                        const note_template_details = note_template.find(item=> item.note_title === event.target.value);
                        setNote_template_details(note_template_details);
                        setNote_subject(note_template_details.subject);
                        setNote_message(note_template_details.note_message);
                     
                    }
                    else
                    {   setNote_template_select(""); 
                        setNote_template_details([]);
                        setNote_subject("");
                        setNote_message("");
                    }
                    };


                    // const handleTeamChange  = (selectedOptions) => {
                    //         if (selectedOptions && selectedOptions.length > 0) {
                    //           // Check if "All" option is selected
                    //           const isAllSelected = selectedOptions.some(option => option.value === 'all');
                          
                    //           if (isAllSelected) {
                    //             // If "All" is selected, select all team members
                    //             const allTeamIds = team.map(item => item.id);
                    //             setTeam_select(allTeamIds);
                    //           } else {
                    //             // If individual team members are selected, update the selected team members
                    //             const selectedTeamMemberIds = selectedOptions.map(option => option.value);
                    //             setTeam_select(selectedTeamMemberIds);
                    //           }
                    //         } else {
                    //           // If no options are selected, clear the selected team members
                    //           setTeam_select([]);
                    //         }
                    //       };
                  
                          const handleTeamChange = (event) => {
                        var updatedList = [...team_select];
                  
                      if (event.target.checked) {
              updatedList = [...team_select, event.target.value];
      
            } else {
              updatedList.splice(team_select.indexOf(event.target.value), 1);
            }
            setTeam_select(updatedList);

          };    
                     
                  
                  
                        const handleUploadTemplateChange  = (event) => {
                        setUpload_template_select(event.target.value);
                        if(event.target.value){
                            const upload_template_details = upload_template.find(item=> item.upload_title === event.target.value);
                            setUpload_template_details(upload_template_details);
                            setUpload_subject(upload_template_details.subject);
                            setUpload_message(upload_template_details.upload_message);
                         
                        }


                        else
                        {   setUpload_template_select(""); 
                            setUpload_template_details([]);
                            setUpload_subject("");
                            setUpload_message("");
                        }
                        };


                    const handlePinTemplateChange  = (event) => {
                        setPin_template_select(event.target.value);
                        if(event.target.value){
                            const pin_template_details = pin_template.find(item=> item.pin_title === event.target.value);
                            setPin_template_details(pin_template_details);
                            setPin_message(pin_template_details.pin_message);
                            
                        }
                        else
                        {   setPin_template_select(""); 
                            setPin_template_details([]);
                            setPin_message("");
                        }
                        };


                        const handleSubmit = async (e) => {
                            e.preventDefault();
                            setLoading(true);
                           
                    
                                await axios.post(`${API_URL}/save-meet-template`, {
                                    title,
                                    start_datetime: startDatetime,
                                    end_datetime: endDatetime
                                }).then(res =>{
                               
                                    Swal.fire({
                                        icon:"success",
                                        text:res.data.message
                                      }).then(function(){ 
                                        setLoading(false);        
                                        resetMeetingData();
                                        setErrorList([]); 
                                        // fetchEvents();
                                    });
                                    }).catch(function(error) {
                                        setLoading(false);
                                        setErrorList(error.response.data.validate_err);
                                        Swal.fire({
                                          text:error.response.data.message,
                                          icon:"error"
                                        })
                                    })
                    
                            }
                    
                    
                        const resetMeetingData = () => {
                            setTitle('');
                            setStartDatetime('');
                            setEndDatetime('');
                        };

                       

                        const handleShowModal = () => setShowModal(true);
                        const handleCloseModal = () => setShowModal(false);

    return(
<div>
   
    <SideNav/>
      <TopBar/>
      {isLoading?
     <div className="loader-container" style ={{backgroundColor: "rgba(0, 0, 0, 0.5)", position: "fixed",top: "0",left: "0",width: "100%",height: "100%",display: "flex",justifyContent: "center",alignItems: "center",zIndex: "9999"}}>
    <div class="d-flex justify-content-center">
  <div class="spinner-border" role="status">
  </div>
  </div>
</div>:null}


      <div class="right_panel position contact_section vh-100" id="right_panel">
        <div class="contact_section_wrapper">
            <div class="d-lg-flex" style={{height:"100vh"}}>
            
                <a id="contact_inbox" class="a_id_contact_inbox position-relative"></a>
                <div class="message_section bg-white">
    <div class="main_title bg-white border-bottom d-flex justify-content-between">
        <h1><i class="bi bi-person-rolodex"></i>Leads</h1>
            <button class="btn btn-sm btn_secondary">
            <Link style={{color:"white"}} to={'/create-customer'}>Create New Leads </Link></button>
    </div>
   
    <div class="p-3 message_item_wrapper">
    {customer.map((item, index) => (
            <div class="item d-flex w-100">
                {/* <div class="profile_photo vm_bg_center vm_bg_cover vm_bg_norepeat me-2" style={{ background: `url('${LARAVEL_API_URL}/${item.customer_photo_path}')` }}></div> */}
                {(isImageLoading ) ? <img class="profile_photo vm_bg_center vm_bg_cover vm_bg_norepeat me-2" onLoad={handleImageLoad} src={placeholderImage} />
                                 
                                 : <img class="profile_photo vm_bg_center vm_bg_cover vm_bg_norepeat me-2"  src={item.customer_photo_path ? `${LARAVEL_API_URL}/${item.customer_photo_path}` : noImagePlaceholder} />

}
                <div class="w-100">
                    <div class="d-flex justify-content-between">
                    <Link to={`/leads-details/${item.id}`}> 
                            <h5 class="vm_font_bold me-3 message_user_name" onClick={() => getCustomerById(item.id)} >{item.first_name} {item.last_name}</h5>
                            </Link>
                        <div class="d-flex align-items-center">
                            <select class="border-0 bg-light message_user_status" value={item.user_type}    onChange={(event) => handleUserType(event, item.id)} >
                                <option value="Customer">Customer</option>
                                <option value="Leads">Leads</option>
                            </select>
                           {item.user_type == "Customer"?<div class="bg-success status_color mx-2"></div>: <div class="bg-warning status_color mx-2"></div>} 
                           
                           <Link to={`/leads-details/${item.id}`}> 
                                <i class="bi bi-arrow-right-square"   onClick={() => getCustomerById(item.id)} ></i>
                                </Link>
                        </div>
                    </div>

                        <div class="d-flex recent_update" >
                            <p>Form Submitted</p>
                            <p class="mx-2">-</p>
                            <p>{moment.utc(item.created_at).format('DD.MM.YYYY - hh:mm A')}</p>

                        </div>
                    
                </div>
            </div>
        
        
        ))}
    </div>
 
</div>
{customerById.map((item, index) => (                                                     
                                
    <><a id="contact_profile" class="a_id_contact_profile position-relative"></a><div class="border border-top-0 border-bottom-0 user_panel bg-white">
        <div class="profile_photo_wrapper vm_bg_cover vm_bg_center vm_bg_norepeat w-100 d-flex align-items-center flex-column">
            <div class="d-flex justify-content-end w-100 position-relative">
                <a href="#">
                    <i class="bi bi-pencil-square position-absolute vm_bg_secondary text-white px-1"></i>
                </a>
            </div>
            <div class="profile_photo bg-light p-1 shadow d-flex justify-content-center">
                {/* <img class="img w-100 h-100" src={`${LARAVEL_API_URL}/${item.customer_photo_path}`} alt="No Profile Photo" /> */}
                {(isPhotoLoading) ? <img class="img w-100 h-100" onLoad={handlePhotoLoad} src={placeholderImage} />
                                 
                                 : <img class="img w-100 h-100"  src={item.customer_photo_path ? `${LARAVEL_API_URL}/${item.customer_photo_path}` : noImagePlaceholder} />

}

            </div>
        </div>

        <h2 class="text-center vm_font_bold pt-4 mt-2">{item.first_name} {item.last_name}</h2>
        <div class="d-flex justify-content-center">
            <a href="tel:${item.phone_no}">
                {/* <div style={{cursor:"pointer"}} class="d-flex align-items-center pe-3"  onClick={() => toggleDiv('call')} > */}
                <div style={{cursor:"pointer"}} class="d-flex align-items-center pe-3"  >
                    <i class="bi bi-telephone me-2"></i>
                    <p>{item.phone_no}</p>
                </div>
           </a>
            {/* <a href="mailto:harry@gmail.com"> */}
                <div style={{cursor:"pointer"}} class="d-flex align-items-center"  onClick={() => toggleDiv('email')}>
                    <i class="bi bi-envelope me-2"></i>
                    <p>{item.email}</p>
                </div>
            {/* </a> */}
        </div>
        <div class="px-4 py-3">
            <div class="progress" style={{ height: "10px" }}>
                <div class="progress-bar vm_bg_secondary" role="progressbar" aria-label="Basic example" style={{ width: "25%", ariaValuenow: "25", ariaValuemin: "0", ariaValuemax: "100" }}></div>
            </div>
        </div>
        <div class="option d-flex justify-content-center flex-wrap pt-2">
                   
        {/* <a href="#" class="d-flex justify-content-center flex-column align-items-center" onClick={() => toggleDiv('settings')}>
                <div class={`item d-flex justify-content-center align-items-center ${activeButton === 'settings' ? 'selected' : ''}`}>
                    <i class="bi bi-gear-fill text-white"></i>
                </div>
                <p class="text-center">Settings</p>
            </a> */}
            
            <a href="#" class="ps-2 d-flex justify-content-center flex-column align-items-center" onClick={() => toggleDiv('inq')}>
                <div class={`item d-flex justify-content-center align-items-center ${activeButton === 'inq' ? 'selected' : ''}`}>
                    <i class="bi bi-question-lg text-white"></i>
                </div>
                <p class="text-center">Inq</p>
            </a>
            
            <a href="#" class="ps-2 d-flex justify-content-center flex-column align-items-center" onClick={() => toggleDiv('call')}>
                <div class={`item d-flex justify-content-center align-items-center ${activeButton === 'call' ? 'selected' : ''}`}>
                    <i class="bi bi-telephone-fill text-white"></i>
                </div>
                <p class="text-center">Call</p>
            </a>
            <a href="#" class="ps-2 d-flex justify-content-center flex-column align-items-center" onClick={() => toggleDiv('email')}>
                <div class={`item d-flex justify-content-center align-items-center ${activeButton === 'email' ? 'selected' : ''}`}>
                    <i class="bi bi-envelope-fill text-white"></i>
                </div>
                <p class="text-center w-100">Email</p>
            </a>
            <a href="#" class="ps-2 d-flex justify-content-center flex-column align-items-center" onClick={() => toggleDiv('text')} >
                <div class={`item d-flex justify-content-center align-items-center ${activeButton === 'text' ? 'selected' : ''}`}>
                    <i class="bi bi-chat-dots-fill text-white"></i>
                </div>
                <p class="text-center w-100">Text</p>
            </a>
            <a href="#" class="ps-2 d-flex justify-content-center flex-column align-items-center"  onClick={() => toggleDiv('pin')}>
                <div class={`item d-flex justify-content-center align-items-center ${activeButton === 'pin' ? 'selected' : ''}`}>
                    <i class="bi bi-geo-fill text-white"></i>
                </div>
                <p class="text-center w-100">Pin</p>
            </a>
            <a href="#" class="ps-2 d-flex justify-content-center flex-column align-items-center"  onClick={() => toggleDiv('meet')}>
                <div class={`item d-flex justify-content-center align-items-center ${activeButton === 'meet' ? 'selected' : ''}`}>
                    <i class="bi bi-calendar-check text-white"></i>
                </div>
                <p class="text-center w-100">Meet</p>
            </a>
            <a href="#" class="ps-2 d-flex justify-content-center flex-column align-items-center"  onClick={() => toggleDiv('note')}>
                <div class={`item d-flex justify-content-center align-items-center ${activeButton === 'note' ? 'selected' : ''}`}>
                    <i class="bi bi-stickies-fill text-white"></i>
                </div>
                <p class="text-center w-100">Note</p>
            </a>
            <a href="#" class="ps-2 d-flex justify-content-center flex-column align-items-center" onClick={() => toggleDiv('upload')}>
                <div class={`item d-flex justify-content-center align-items-center ${activeButton === 'upload' ? 'selected' : ''}`}>
                    <i class="bi bi-cloud-arrow-up-fill text-white"></i>
                </div>
                <p class="text-center w-100">Upload</p>
            </a>
        </div>

<div>

</div>
{settingsVisible && <div id="settingsDiv" class="px-3 mb-5 bg-light mt-3 pb-4 pt-3">
  
      <div className="row">
        <div className="col-md-7">
          <h4 className="pb-2 mb-2 vm_font_semi_bold">Assign to</h4>
        </div>
        <div className="col-md-5 form-group">
          <div className="d-flex input_wrapper">
            <div className="icon_wrapper">
              <i className="bi bi-search icon"></i>
            </div>
            <input className="form-control" name="teamMember" id="teamMember"
             placeholder="Team Member" type="text"  value={searchQuery} onChange={(e) => {setSearchQuery(e.target.value)}} />
          </div>
        </div>
      </div>
      <div className="row py-3 border-top">
        <div className="col-md-12">
          <div className="form-group-checkbox d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <input
                style={{ boxShadow: "2px 2px #059618", transition: "all .5s ease" }}
                className="checkbox"
                type="checkbox"
                onChange={handleSelectAllTeam}
                checked={team_select.length === team.length && team.length > 0} // Check if all members are selected
              />
              <label htmlFor="selectAll" className="ml-2">Select All</label>
            </div>
          </div>
        </div>
      </div>
      <div className="row py-3">
      {filteredTeam.length === 0 ? 
                

                <div class="row" style={{margin: "0px -550px 0px 0px"}}>
                <p id="recordMessage" valign="top" class="odd" style={{ textAlign: "center" }} >No records found</p> 
                </div>
                  : null} 
      {filteredTeam.map((item, index) => (
          <div className="col-md-4 pb-2" key={index}>
            <div className="form-group-checkbox d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <input style={{boxShadow: "2px 2px #059618",transition: "all .5s ease"}}
                  className="checkbox"
                  value={item.id}
                  type="checkbox"
                  name="team_select"
                  id={`team_select${index}`}
                  checked={team_select.includes(item.id.toString())}
                //   checked={team_select.includes(item.id)}
                  onChange={ handleTeamChange}
                />
                <label
                  htmlFor={`team_select${index}`}
                  className={team_select.includes(item.id.toString()) ? 'checked' : ''}
                >
                  {item.first_name} {item.last_name}
                </label>
              </div>
            </div>
          </div>
          
        ))}
          <div class="d-flex justify-content-end pt-3">
                            <button onClick={saveAssign}  class="btn btn-sm btn_secondary text-white" ><i class="bi bi-send"></i> {loading?"Saving...":"Save"} </button>
                        </div>
      </div>

                           
                      




{/* 
                  <div class="d-md-flex">
                  <div class="col-md-3 form-group"> 
                       <label htmlFor="assignTo">Assign to:</label>
                       </div>
                  <div class="col-md-6 form-group">

<Select 
  value={team_select.map(id => ({ value: id, label: `${team.find(item => item.id === id).first_name} ${team.find(item => item.id === id).last_name}` }))} 
  options={[
    { value: 'all', label: 'All' }, // Adding the "All" option
    ...team.map(item => ({ value: item.id, label: `${item.first_name} ${item.last_name}` }))
  ]}
  isMulti
  placeholder="Select Team members"
  onChange={handleTeamChange}
  menuPortalTarget={document.body}
  noOptionsMessage={() => 'No options available'} 

/>


                      </div>
                  </div> */}
          

    </div>}

      {inqVisible && <div id="inqDiv" class="px-3 mb-5 bg-light mt-3 pb-4 pt-3">
        
     
                        <h3 class="vm_font_bold pb-3">Inquery</h3>
                        {/* <div class="form-group">
                            <label for="vehicle_name">Vehicle Name</label>
                            <input type="text" class="form-control" name="vehicle_name" value={vehicle_name} id="vehicle_name" placeholder="Ex: 2015 Chevrolet Equinox"
                            onChange={(event) =>{ setVehicle_name(event.target.value);}}/>
                             {vehicle_name.length === 0 && (
    <span class="text-danger">{error_list.vehicle_name}</span>
                             )}
                        </div>
                    
                        <div class="d-md-flex gx-5">
                        <div class="form-group col pe-md-2">
                        <label >Make</label>
                        <Select 
        value={selectedMakes.map(make => ({ value: make, label: make}))}
        options={makeOptions.map(make => ({ value: make, label: make }))}
        isMulti
        placeholder="Select Make"
        onChange={handleMakeChange}
        // menuPortalTarget={document.body}
        noOptionsMessage={() => 'No options available'}
      />
       {selectedMakes.length === 0 && (
        <span class="text-danger">{error_list.selectedMakes}</span>
    )}
    
      </div>
     
      <div class="form-group col ps-md-2">
      <label >Model</label>
                                    <Select 
        value={selectedModels.map(model => ({ value: model, label: model}))}
        options={modelOptions.map(model => ({ value: model, label: model }))}
        isMulti
        placeholder="Select Model"
        onChange={handleModelChange}
        // menuPortalTarget={document.body}
        noOptionsMessage={() => 'No options available'}
        required  
      />
        {selectedModels.length === 0 && (
        <span class="text-danger">{error_list.selectedModels}</span>
    )}
      </div>
    

      </div>

                        
                        <div class="d-md-flex gx-5">
                            <div class="form-group col pe-md-2">
                                <label for="minPrice">Min Price</label>
                                <input type="number" class="form-control"  name="minPrice" id="minPrice"  value={minPrice} onChange={(e) => setMinPrice(e.target.value)} min="0"/>
                            </div>
                            <div class="form-group col ps-md-2">
                                <label for="maxPrice">Max Price</label>
                                <input type="number" class="form-control "  name="max_price" id="max_price"  value={maxPrice} onChange={(e) => setMaxPrice(e.target.value)} min="0"/>
                            </div>
                        </div>
                        <div class="d-md-flex gx-5">
                            <div class="form-group col pe-md-2">
                                <label for="minPrice">Min KM</label>
                                <input  type="number" class="form-control "  name="minKilometer" id="minKilometer"
            value={minKilometer} onChange={(e) => setMinKilometer(e.target.value)} min="0"/>
                            </div>
                            <div class="form-group col ps-md-2">
                                <label for="maxPrice">Max KM</label>
                                <input  type="number" class="form-control "  name="maxKilometer" id="maxKilometer"
            value={maxKilometer} onChange={(e) => setMaxKilometer(e.target.value)} min="0"/>
                            </div>
                        </div>


                        <div class="d-md-flex gx-5">
                            <div class="form-group col pe-md-2">
                            <label >Body Style</label>
  <Select 
    value={selectedBodyStyles.map(style => ({ value: style, label: style}))}
    options={bodyStyleOptions.map(style => ({ value: style, label: style }))}
    isMulti
    placeholder="Select Body Style"
    onChange={handleBodyStyleChange}
    noOptionsMessage={() => 'No options available'}
  />
                            </div>
                            <div class="form-group col ps-md-2">
                            <label >Engine</label>
  <Select 
    value={selectedEngines.map(engine => ({ value: engine, label: engine}))}
    options={engineOptions.map(engine => ({ value: engine, label: engine }))}
    isMulti
    placeholder="Select Engine"
    onChange={handleEngineChange}
    noOptionsMessage={() => 'No options available'}
  />
                            </div>
                        </div>
                        <div class="d-md-flex gx-5">
                            <div class="form-group col pe-md-2">
                            <label >Exterior Color</label>
  <Select 
    value={selectedExteriorColors.map(color => ({ value: color, label: color}))}
    options={exteriorColorOptions.map(color => ({ value: color, label: color }))}
    isMulti
    placeholder="Select Exterior Color"
    onChange={handleExteriorColorChange}
    noOptionsMessage={() => 'No options available'}
  />
                            </div>
                            <div class="form-group col ps-md-2">
                            <label >Transmission</label>
  <Select 
    value={selectedTransmissions.map(transmission => ({ value: transmission, label: transmission}))}
    options={transmissionOptions.map(transmission => ({ value: transmission, label: transmission }))}
    isMulti
    placeholder="Select Transmission"
    onChange={handleTransmissionChange}
    noOptionsMessage={() => 'No options available'}
  />
                            </div>
                        </div>
                        <div class="d-md-flex gx-5">
                            <div class="form-group col pe-md-2">
                            <label >Drive Trains</label>
  <Select 
    value={selectedDriveType.map(driveType => ({ value: driveType, label: driveType}))}
    options={driveTypeOptions.map(driveType => ({ value: driveType, label: driveType }))}
    isMulti
    placeholder="Select Drive Train"
    onChange={handleDriveTypeChange}
    noOptionsMessage={() => 'No options available'}
  />
                            </div>
                            <div class="form-group col pe-md-2">
                            <label >Doors</label>
  <Select 
    value={selectedDoors.map(doors => ({ value: doors, label: doors}))}
    options={doorsOptions.map(doors => ({ value: doors, label: doors }))}
    isMulti
    placeholder="Select No of door"
    onChange={handleDoorChange}
    noOptionsMessage={() => 'No options available'}
  />
                            </div>
                            <div class="form-group col pe-md-2">
                            <label for="stock_no">Stock No</label>
                            <Select 
    value={selectedStockNo.map(stockNo => ({ value: stockNo, label: stockNo}))}
    options={stockNoOptions.map(stockNo => ({ value: stockNo, label: stockNo }))}
    isMulti
    placeholder="Select Stock No"
    onChange={handleStockNoChange}
    noOptionsMessage={() => 'No options available'}
  />
                            </div>
                        </div> */}


                        <div class="form-group col">
                        <label for="description">Description</label>
                            <textarea class="form-control" name="description" id="description" value={description} cols="" rows="3"
                            onChange={(event) =>{ setDescription(event.target.value);}}/>
                        </div>
                        <div class="d-flex justify-content-center pt-3">
                            <button onClick={saveInquiry} class="btn btn-sm btn_secondary text-white" ><i class="bi bi-send"></i> {loading?"Submitting...":"Submit Inquery"} </button>
                        </div>
                  

           
        
        </div>}
      {callVisible && <div id="callDiv">
      {/* <a href="tel:+94763125620">

        <button  class="btn btn-sm btn_secondary text-white">{loading?"Calling...":"Call"}</button>
        </a> */}
      <h3 class="vm_font_bold ps-3 pt-3">Call History</h3>

<div class="activity_wrapper">
    <div class="row pt-3">
        <div class="col-md-4 col-4 d-flex justify-content-center align-items-center pe-md-0">
            <div class="border-bottom col"></div>
            <div class="vm_bg_light_red px-2 py-1 text-center">03 Feb</div>
            <div class="border-bottom col"></div>
        </div>
        <div class="col-md-8 col-8 vm_bg_light_red p-2">
            <p class="vm_font_bold">Call | Business LIne</p>
            <p>Outgoing | Wed at 07:45am</p>
        </div>
    </div>
    <div class="row pt-3">
        <div class="col-md-4 col-4 d-flex justify-content-center align-items-center pe-md-0">
            <div class="border-bottom col"></div>
            <div class="vm_bg_light_blue px-2 py-1 text-center">02 Feb</div>
            <div class="border-bottom col"></div>
        </div>
        <div class="col-md-8 col-8 vm_bg_light_blue p-2">
            <p class="vm_font_bold">Call | Business LIne</p>
            <p>Outgoing | Wed at 07:45am</p>
        </div>
    </div>
</div>
        
        
        </div>}
      {emailVisible && <div id="emailDiv" >


        <Modal show={showModal} onHide={handleCloseModal}size="xl">
    <Modal.Header closeButton >
        <Modal.Title>Compose Email</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <div className="px-3 pb-3 pt-2 bg-light mt-3">
            {/* <h3 className="vm_font_semi_bold border-bottom mb-3 pb-2">
                <i className="bi bi-pencil"></i> Compose Email
            </h3> */}
            <div className="form-group row pb-2">
                <div className="col-md-2 d-flex pt-2">
                    <label htmlFor="toemail">To email</label>
                </div>
                <div className="col-md-10">
                    <input 
                        className="form-control col" 
                        type="text" 
                        name="toemail" 
                        id="toemail" 
                        value={email} 
                        placeholder="Enter to Email"
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
            </div>
            <div className="form-group row pb-2">
                <div className="col-md-2 d-flex pt-2">
                    <label htmlFor="subject">Subject</label>
                </div>
                <div className="col-md-10">
                    <input 
                        className="form-control col" 
                        type="text" 
                        name="subject" 
                        id="subject" 
                        value={subject} 
                        onChange={(event) => setSubject(event.target.value)}
                        placeholder="Enter Subject"
                    />
                    <span className="text-danger">{email_error_list.subject}</span>
                </div>
            </div>

            <div className="form-group pb-2">
                <label htmlFor="message">Message</label>
                <div
  id="editor-container" ref={editorContainer}
  style={{ height: '600px', border: '1px solid #ccc' }}
  name="message"
  value={message}
  placeholder="Enter Message"
  onChange={(event) => { setMessage(event.target.value); }}
></div>
                <span className="text-danger">{email_error_list.message}</span>
            </div>

            <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center" style={{ marginLeft: "4px" }}>
                    <label className="vm_cursor_pointer" htmlFor="image">
                        <i className="bi bi-card-image h2"></i>
                    </label>
                    <input 
                        className="d-none" 
                        type="file" 
                        name="image" 
                        id="image" 
                        multiple 
                        onChange={onSelectImages}
                    />

                    <select
                        className="form-select ms-3 form-select-sm"
                        name="template"
                        id="template"
                        value={template}
                        onChange={handleTemplateChange}
                    >
                        <option value="">Select Template</option>
                        {email_template.map((item) => (
                            <option value={item.template_name} key={item.template_name}>
                                {item.template_name}
                            </option>
                        ))}
                    </select>
                </div>

                {successMessage && <p className="text-left" style={{ color: "green" }}>{successMessage}</p>}
                <div>
                    <button 
                        onClick={sendEmail} 
                        className="btn btn-sm btn_secondary text-white"
                    >
                        <i className="bi bi-send"> </i>{loading ? "Sending..." : "Send Message"}
                    </button>
                </div>
            </div>

            <div className="container" style={{ maxHeight: "200px", overflowY: "auto", display: "flex", padding: "15px" }}>
                {previews.length > 0 && previews.map((preview, index) => (
                    <div key={index} style={{ position: "relative", marginRight: "5px", marginBottom: "8px", height: "75px", width: "75px" }}>
                        <span
                            style={{
                                position: "absolute",
                                cursor: "pointer",
                                top: "-2px",
                                right: "2px",
                                fontSize: "20px",
                                color: "limegreen"
                            }}
                            className="delete"
                            onClick={() => deleteImage(index)}
                        >
                            &times;
                        </span>
                        {preview.type === 'image' && (
                            <img
                                loading="lazy"
                                onClick={() => handleImageClick(index)}
                                style={{ width: "100%", height: "100%", borderRadius: "5px" }}
                                src={preview.url}
                                alt={`Preview ${index}`}
                            />
                        )}

                        {preview.type === 'pdf' && (
                            <img
                                src="/img/pdf_icon.jpg"
                                alt={`Preview ${index}`}
                                onClick={() => handlePdfClick(index)}
                                style={{ width: "100%", height: "100%", borderRadius: "5px" }}
                            />
                        )}

                        {preview.type === 'video' && (
                            <video
                                src={preview.url}
                                alt={`Preview ${index}`}
                                onClick={() => handleVideoClick(index)}
                                style={{ width: "100%", height: "100%", borderRadius: "5px" }}
                            />
                        )}
                    </div>
                ))}
            </div>
        </div>
    </Modal.Body>
    <Modal.Footer>
      


      
      
    </Modal.Footer>
</Modal>





        
      {/* <div class="px-3 pb-3 pt-2 bg-light mt-3">
                        <h3 class="vm_font_semi_bold border-bottom mb-3 pb-2">
                            <i class="bi bi-pencil"></i> Compose Email
                        </h3>
                        <div class="form-group row pb-2">
                            <div class="col-md-2 d-flex pt-2">
                                <label for="toemail">To email</label>
                            </div>
                            <div class="col-md-10">
                                <input class="form-control col" type="text" name="toemail" id="toemail" value={email} placeholder="Enter to Email"/>
                            </div>
                        </div>
                       
 <div class="form-group row pb-2">
                            <div class="col-md-2 d-flex pt-2">
                                <label for="toemail">Subject</label>
                            </div>
                            <div class="col-md-10">
                                <input class="form-control col" type="text" name="subject" id="subject" value={subject} onChange={(event) =>{ setSubject(event.target.value); }}
                                 placeholder="Enter Subject"/>
                                <span class="text-danger">{email_error_list.subject}</span> 
                            </div>
                        </div>

                        <div class="form-group pb-2">
                            <label for="toemail">Message</label>
                            <div
  id="editor-container" ref={editorContainer}
  style={{
    width: '100%',
    minHeight: '200px', // Ensure the editor has a minimum height
    height: '300px', // Let it expand based on content
    maxHeight: '400px', // Prevent it from growing too large
    border: '1px solid #ccc',
    resize: 'vertical', // Allow vertical resizing for easier expansion
    overflowY: 'auto', // Make the content scrollable if it exceeds height
    padding: '10px',
    boxSizing: 'border-box', // Ensure padding does not affect the width/height calculation
  }}
  name="message"
  value={message}
  placeholder="Enter Message"
  onChange={(event) => { setMessage(event.target.value); }}
></div>


                             <span class="text-danger">{email_error_list.message}</span> 
                            
                        </div>
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="d-flex align-items-center" style={{ marginLeft:"4px"}}>
                                <label class="vm_cursor_pointer" for="image"><i class="bi bi-card-image h2"></i></label>
                                <input class="d-none" type="file" name="image" id="image" multiple onChange= {onSelectImages}/>
 */}

 

                                {/* <label class="vm_cursor_pointer ps-2" for="video"><i class="bi bi-film h3"></i></label>
                                <input class="d-none" type="file" name="video" id="video" multiple
                             onChange= {onSelectVideos}/>

                               <div className="container"  style={{ maxHeight: "200px", overflowY: "auto", display: "flex"}}>
                                            { previewsVideo.length > 0 &&  previewsVideo.map((previewUrl, index, file) => (
                                                <div key={index} style={{ position: "relative", marginRight: "5px", marginBottom: "8px", height: "75px", width: "75px",  zIndex: "1" }}  >
                                                  
                                                 <video loading="lazy" style={{ width: "100%" , height: "100%", borderRadius: "5px" }}  src={previewUrl} alt={`Preview ${index}`} />  
                                                 <span  style={{position:"absolute",cursor: "pointer",top: "-2px",right: "2px", fontSize: "20px", color:"limegreen"}}
                                                   onClick={() => deleteVideo(index)}>&times;</span>
                                                </div>
                                            ))}
                                        </div> */}

                                {/* <select class="form-select ms-3 form-select-sm" name="template" id="template" value={template} onChange={handleTemplateChange}>
                                    <option value="">Select Template</option>
                                    {email_template.map((item) => (
                                    <option value={item.template_name}>{item.template_name}</option>
                                    ))}
                                </select>

                            </div>



                            {successMessage && <p class="text-left" style={{color:"green"}}>{successMessage}</p>}
                            <div>

                                <button onClick={sendEmail} class="btn btn-sm btn_secondary text-white"><i class="bi bi-send"> </i>{loading?"Sending...":"Send Message"} </button>
                            </div>

                            
                        </div> */}
                        {/* <div className="container"  style={{ maxHeight: "200px", overflowY: "auto", display: "flex" , padding:"15px"}}>
                        { previews.length > 0 && previews.map((preview, index) => (
                                                <div key={index} style={{ position: "relative", marginRight: "5px", marginBottom: "8px", height: "75px", width: "75px" }}  >
                                                   <span  style={{position:"absolute",cursor: "pointer",top: "-2px",right: "2px", fontSize: "20px", color:"limegreen"}}
                                                   className="delete" onClick={() => deleteImage(index)}>&times;</span>
                                                {preview.type === 'image' && (
                                                <img
                                                    loading="lazy"
                                                    onClick={() => handleImageClick(index)}
                                                    style={{ width: "100%", height: "100%", borderRadius: "5px" }}
                                                    src={preview.url}
                                                    alt={`Preview ${index}`}
                                                />
                                            )}
                                    
                                    {preview.type === 'pdf' && (
                                                
                                                    <img src="/img/pdf_icon.jpg" alt={`Preview ${index}`}  onClick={() => handlePdfClick(index)} 
                                                    style={{ width: "100%", height: "100%", borderRadius: "5px" }}/>
                                                
                                             
                                            )}
                                    
                                    {preview.type === 'video' && (
                                             
                                                <video  src={preview.url} alt={`Preview ${index}`}  onClick={() => handleVideoClick(index)} 
                                                style={{ width: "100%", height: "100%", borderRadius: "5px" }}/>
                                           
                                        )}
                                                </div>
                                            ))}
                                        </div>
                    </div> */}

<div className="d-flex justify-content-between align-items-center">
  <h3 className="vm_font_bold ps-3 pt-2">Email History</h3>
  <button class="btn btn-sm btn_secondary text-white me-3 mt-2" onClick={handleShowModal}>Compose Email</button>
</div>
                    
                    <div class="content">
           
                    <div class="row pt-3" style={{padding:"10px"}}>
                    <div class="col-md-8 form-group" >
           
                                  <div class="d-flex input_wrapper" >
                                  <div ><label>Show   <select name="example_length" aria-controls="example" value={perPage} onChange={(e) => {setCurrentPage(0);setPerPage(Number(e.target.value))}}>
                                      <option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option>
                                    </select>  entries</label>
                                    </div>
                                  </div>
                              </div>
  
                              <div class="col-md-4 form-group">
                                  <div class="d-flex input_wrapper">
                                  
                                      <input class="form-control" name="searchQuery" id="searchQuery" placeholder="Search..." type="text" 
                                      value={searchQuery} onChange={(e) => {setSearchQuery(e.target.value)}}/>
                                  </div>
                              </div>
                              </div>
                    {filteredEmailHistory.length==0?
                <p style={{ textAlign: "center" }}>No records found</p>
                :
(filteredEmailHistory.slice((currentPage * perPage), ((currentPage * perPage) + perPage)).map((item, index) => (
                    <div class="activity_wrapper pb-3 mb-1">
                        <div class="row pt-1">
                            <div class="col-md-4 col-4 d-flex justify-content-center align-items-center pe-md-0">
                                <div class="border-bottom col"></div>
                                <div class="vm_bg_light_red px-2 py-1 text-center">{formatDate(item.created_at)}</div>
                                <div class="border-bottom col"></div>
                            </div>
                            <div class="col-md-8 col-8 vm_bg_light_red p-2">
                                <p class="vm_font_bold">Subject | {item.subject}</p>
                                <p>{getDayName(item.created_at)} at {formatTime(item.created_at)}</p>
                            </div>
                            {/* <div class="col-md-8 col-8 vm_bg_light_red p-2">
                                <p class="vm_font_bold">Subject | {item.subject}</p>
                                <p>Link | {item.message.match(/(https?:\/\/[^\s]+)/g) && item.message.match(/(https?:\/\/[^\s]+)/g).find(url => url.includes("vehicle-list")) ? (
        <a href={item.message.match(/(https?:\/\/[^\s]+)/g).find(url => url.includes("vehicle-list"))} target="_blank">View Vehicle List</a>
    ) : (
        "No vehicle list available"
    )}</p>
                            </div> */}
                        </div>                    
                    </div>
                    )))}
<div class="col-md-4" style={{ float: "left", padding: "20px 0px 0px 10px" }}>
                                        {filteredEmailHistory.length > 0 && `Showing 1 to ${Math.min(perPage, filteredEmailHistory.length)} of ${emailLogById.length} entries`}
                                    </div><div class="col-md-8" style={{ float: "right" }}>

                                        <ReactPaginate style={{ float: "right" }}
                                            previousLabel={"< Prev"}
                                            nextLabel={"Next >"}
                                            breakLabel={'...'}
                                            pageCount={Math.ceil(filteredEmailHistory.length / perPage)}
                                            onPageChange={handlePageChange}
                                            containerClassName={"pagination pagination-sm justify-content-center custom-pagination"} // Bootstrap pagination class
                                            disabledClassName={"disabled"}
                                            breakClassName={['page-item']}
                                            breakLinkClassName={'page-link'}
                                            pageClassName={'page-item'}
                                            pageLinkClassName={'page-link'}
                                            previousClassName={'page-item'}
                                            previousLinkClassName={'page-link'}
                                            nextClassName={'page-item'}
                                            nextLinkClassName={'page-link'}
                                            activeClassName={['active']} />


                                    </div>

                    </div>
                
        
        </div>}
      {textVisible && <div id="textDiv">
        
      <div class="py-4 mt-4 border-top bg-light">
                        <div class="form-group pb-4 px-3">
                            <label for="toemail">Text Message</label>
                            <textarea class="mh-100 form-control" name="text_message" id="text_message" cols="100%" rows="3" value={text_message}  placeholder="Enter Text Message"
                            onChange={(event) =>{ setText_message(event.target.value); }}></textarea>
                            <span  class="text-danger">{error_list.message}</span>
                        </div>
                        <div class="d-md-flex">
                            <div>
                            <select class="form-select ms-3 form-select-sm" name="text_template" id="text_template" value={text_template} onChange={handleTextTemplateChange}>
                                    <option value="">Select Template</option>
                                    {sms_template.map((item) => (
                                    <option value={item.template_name}>{item.template_name}</option>
                                    ))}
                                </select>
                            </div>
                            <div class="ms-auto me-3">
                                <button  onClick={sendSMS} class="btn btn-sm btn_secondary text-white ms-md-4"><i class="bi bi-send"></i>{loading?' Sending...':' Send SMS'}</button>
                            </div>
                        </div>
                    </div>

                    <h3 class="vm_font_bold ps-3 pt-2">Text History</h3>
                    <div class="content">
                    <div class="row pt-3" style={{padding:"10px"}}>
                    <div class="col-md-9 form-group" >
                 
                                  <div class="d-flex input_wrapper" >
                                  <div ><label>Show   <select name="example_length" aria-controls="example" value={perPage} onChange={(e) => {setCurrentPage(0);setPerPage(Number(e.target.value))}}>
                                      <option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option>
                                    </select>  entries</label>
                                    </div>
                                  </div>
                              </div>
  
                              {/* <div class="col-md-4 form-group">
                                  <div class="d-flex input_wrapper">
                                  
                                      <input class="form-control" name="searchQuery" id="searchQuery" placeholder="Search..." type="text" 
                                      value={searchQuery} onChange={(e) => {setSearchQuery(e.target.value)}}/>
                                  </div>
                              </div> */}

<div class="col-md-3 form-group">
    <div class="d-flex input_wrapper">
       <div> <label>Sort by <select  value={sortOrder}   onChange={(e) => setSortOrder(e.target.value)} >
                <option value="newest">Newest</option>
                <option value="oldest">Oldest</option>
            </select>
        </label>
        </div>
    </div>
</div>
                              </div>
                    {sortedSmsHistory.length==0?
                <p style={{ textAlign: "center" }}>No records found</p>
                :
(sortedSmsHistory.slice((currentPage * perPage), ((currentPage * perPage) + perPage)).map((item, index) => (
                    <div class="activity_wrapper pb-3 mb-1">
                        <div class="row pt-1">
                            <div class="col-md-4 col-4 d-flex justify-content-center align-items-center pe-md-0">
                                <div class="border-bottom col"></div>
                                <div class="vm_bg_light_blue px-2 py-1 text-center">{formatDate(item.created_at)}</div>
                                <div class="border-bottom col"></div>
                            </div>
                            <div class="col-md-8 col-8 vm_bg_light_blue p-2">
                                <p class="vm_font_bold">Message | SMS</p>
                                <p>{getDayName(item.created_at)} at {formatTime(item.created_at)}</p>
                            </div>
                        </div>
                      
                    </div>
                        )))}
                        <div class="col-md-4" style={{ float: "left", padding: "20px 0px 0px 10px" }}>
                                                                {sortedSmsHistory.length > 0 && `Showing 1 to ${Math.min(perPage, sortedSmsHistory.length)} of ${smsLogById.length} entries`}
                                                            </div><div class="col-md-8" style={{ float: "right" }}>
                        
                                                                <ReactPaginate style={{ float: "right" }}
                                                                    previousLabel={"< Prev"}
                                                                    nextLabel={"Next >"}
                                                                    breakLabel={'...'}
                                                                    pageCount={Math.ceil(sortedSmsHistory.length / perPage)}
                                                                    onPageChange={handlePageChange}
                                                                    containerClassName={"pagination pagination-sm justify-content-center custom-pagination"} // Bootstrap pagination class
                                                                    disabledClassName={"disabled"}
                                                                    breakClassName={['page-item']}
                                                                    breakLinkClassName={'page-link'}
                                                                    pageClassName={'page-item'}
                                                                    pageLinkClassName={'page-link'}
                                                                    previousClassName={'page-item'}
                                                                    previousLinkClassName={'page-link'}
                                                                    nextClassName={'page-item'}
                                                                    nextLinkClassName={'page-link'}
                                                                    activeClassName={['active']} />
                        
                        
                                                            </div>
                        
                                            </div>
               
        
        
        </div>}
      {pinVisible && <div id="pinDiv">
      
      <div class="py-4 mt-4 border-top bg-light">
                        <div class="form-group pb-4 px-3">
                            <label for="toemail">Enter Pin Message</label>
                            <textarea class="mh-100 form-control" name="pin_message" id="pin_message" cols="100%" value={pin_message}
                            onChange={(event) =>{ setPin_message(event.target.value); }} rows="3"></textarea>
                             <span  class="text-danger">{error_list.pin_message}</span>
                        </div>
                        <div class="d-md-flex justify-content-start">
                            <div>
                            <select class="form-select ms-3 form-select-sm" name="pin_template_select" id="pin_template_select" value={pin_template_select} onChange={handlePinTemplateChange}>
                                    <option value="">Select Pin Template</option>
                                    {pin_template.map((item) => (
                                    <option value={item.pin_title}>{item.pin_title}</option>
                                    ))}
                                </select>
                            </div>
                            <div class="ms-auto me-3">
                                <button class="btn btn-sm btn_secondary text-white ms-md-4"><i class="bi bi-send"></i>{loading?' Sending...':' Send Message'}</button>
                            </div>
                        </div>
                    </div>

                    <h3 class="vm_font_bold ps-3 pt-2">Pin History</h3>

                    <div class="activity_wrapper pb-5 mb-5">
                        <div class="row pt-1">
                            <div class="col-md-4 col-4 d-flex justify-content-center align-items-center pe-md-0">
                                <div class="border-bottom col"></div>
                                <div class="vm_bg_light_red px-2 py-1 text-center">03 Feb</div>
                                <div class="border-bottom col"></div>
                            </div>
                            <div class="col-md-8 col-8 vm_bg_light_red p-2">
                                <p class="vm_font_bold">Call | Business LIne</p>
                                <p>Outgoing | Wed at 07:45am</p>
                            </div>
                        </div>
                        <div class="row pt-3">
                            <div class="col-md-4 col-4 d-flex justify-content-center align-items-center pe-md-0">
                                <div class="border-bottom col"></div>
                                <div class="vm_bg_light_blue px-2 py-1 text-center">02 Feb</div>
                                <div class="border-bottom col"></div>
                            </div>
                            <div class="col-md-8 col-8 vm_bg_light_blue p-2">
                                <p class="vm_font_bold">Call | Business LIne</p>
                                <p>Outgoing | Wed at 07:45am</p>
                            </div>
                        </div>
                    </div>
                
      </div>}
      {meetVisible && <div id="meetDiv">
      <div class="py-4 mt-4 border-top bg-light">

      <div className="content px-3">
                         
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                                <label htmlFor="meeting_title">Enter Title</label>
                                            <input type="text" className="form-control" id="meeting_title" value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Ex: Meeting..." />
                                            <span class="text-danger">{meetingErrorList.title}</span>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group py-2">
                                            <label htmlFor="date">Select Date</label>
                                            <div class="d-flex input_wrapper">
                                                <div class="icon_wrapper">
                                                    <i class="bi bi-calendar2-plus icon"></i>
                                                </div>
                                                {/* <input type="datetime-local" className="form-control" id="start_datetime" value={startDatetime} onChange={(e) => setStartDatetime(e.target.value)} /> */}
                                         
          <input type="date" id="date" value={date} className="form-control" onChange={handleDateChange} required />
     

                                            </div>
                                            <span class="text-danger">{meetingErrorList.date}</span>
                                        </div>
                                    </div>


                                    <div className="col-md-6">
                                    <div className="form-group py-2">
          <label htmlFor="time">Select Time:</label>
          <select id="time" value={time} className="form-control" onChange={(e) => setTime(e.target.value)} required>
            <option value="">Select Time</option>
            {availableTimeSlots.map((timeSlot, index) => (
              <option key={index} value={timeSlot}>{timeSlot}</option>
            ))}
          </select>
          <span class="text-danger">{meetingErrorList.time}</span>
          </div>
        </div>




                                    {/* <div className="col-md-6">
                                        <div className="form-group py-2">
                                            <label htmlFor="end_datetime">Select End date and time</label>
                                            <div class="d-flex input_wrapper">
                                                <div class="icon_wrapper">
                                                    <i class="bi bi-calendar2-plus icon"></i>
                                                </div>
                                                <input type="datetime-local" className="form-control" id="end_datetime" value={endDatetime} onChange={(e) => setEndDatetime(e.target.value)} />
                                             
                                            </div>
                                            <span class="text-danger">{error_list.end_datetime}</span>
                                        </div>
                                    </div> */}
                                </div>

                                <div class="d-flex justify-content-end ms-auto py-3">
                                        <button onClick={sendMeetingEmail} class="btn btn-sm btn_secondary text-white "><i class="bi bi-send"></i>  {loading ? "Sending..." : "Send Message"} </button>
                                </div>
                         
                                </div>
        
      </div> 

      <h3 class="vm_font_bold ps-3 pt-2">Meet History</h3>


<div class="content">
<div class="row pt-3" style={{padding:"10px"}}>
<div class="col-md-8 form-group" >

              <div class="d-flex input_wrapper" >
              <div ><label>Show   <select name="example_length" aria-controls="example" value={perPage} onChange={(e) => {setCurrentPage(0);setPerPage(Number(e.target.value))}}>
                  <option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option>
                </select>  entries</label>
                </div>
              </div>
          </div>

          <div class="col-md-4 form-group">
              <div class="d-flex input_wrapper">
              
                  <input class="form-control" name="searchQuery" id="searchQuery" placeholder="Search..." type="text" 
                  value={searchQuery} onChange={(e) => {setSearchQuery(e.target.value)}}/>
              </div>
          </div>
          </div>


<div class="activity_wrapper pb-5 mb-5">


{filteredMeetHistory.length==0?
<p style={{ textAlign: "center" }}>No records found</p>
:
(filteredMeetHistory.slice((currentPage * perPage), ((currentPage * perPage) + perPage)).map((item, index) => (
<div class="activity_wrapper pb-3 mb-1">
    <div class="row pt-1">
        <div class="col-md-4 col-4 d-flex justify-content-center align-items-center pe-md-0">
            <div class="border-bottom col"></div>
            <div class="vm_bg_light_green px-2 py-1 text-center">{formatDate(item.created_at)}</div>
            <div class="border-bottom col"></div>
        </div>
        <div class="col-md-8 col-8 vm_bg_light_green p-2">
            <p class="vm_font_bold">{item.subject} on {formatMeetDate(item.date)} at {formatMeetTime(item.time)} </p>
            <p>{getDayName(item.created_at)} at {formatTime(item.created_at)}</p>
        </div>
       
    </div>                    
</div>
)))}
<div class="col-md-4" style={{ float: "left", padding: "20px 0px 0px 10px" }}>
                    {filteredMeetHistory.length > 0 && `Showing 1 to ${Math.min(perPage, filteredMeetHistory.length)} of ${meetLogById.length} entries`}
                </div><div class="col-md-8" style={{ float: "right" }}>

                    <ReactPaginate style={{ float: "right" }}
                        previousLabel={"< Prev"}
                        nextLabel={"Next >"}
                        breakLabel={'...'}
                        pageCount={Math.ceil(filteredMeetHistory.length / perPage)}
                        onPageChange={handlePageChange}
                        containerClassName={"pagination pagination-sm justify-content-center custom-pagination"} // Bootstrap pagination class
                        disabledClassName={"disabled"}
                        breakClassName={['page-item']}
                        breakLinkClassName={'page-link'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}
                        nextClassName={'page-item'}
                        nextLinkClassName={'page-link'}
                        activeClassName={['active']} />


                </div>





</div>

</div>


      </div>
    
      
      }



      {noteVisible && <div id="noteDiv">
        
        
      <div class="py-4 mt-4 border-top bg-light">

      <div class="form-group pb-4 px-3">
                            <label for="note_subject">Subject</label>
                            <input class="mh-100 form-control" name="note_subject" id="note_subject" cols="100%" value={note_subject}
                            onChange={(event) =>{ setNote_subject(event.target.value); }} placeholder="Enter Subject"/>
                             <span  class="text-danger">{noteErrorList.note_subject}</span>
                        </div>

                        <div class="form-group pb-4 px-3">
                            <label for="note_message">Note Message</label>
                            <textarea class="mh-100 form-control" name="note_message" id="note_message" cols="100%" value={note_message}
                            onChange={(event) =>{ setNote_message(event.target.value); }} rows="3" placeholder="Enter Note Message"></textarea>
                             <span  class="text-danger">{noteErrorList.note_message}</span>
                        </div>
                        <div class="d-md-flex">
                            <div>
                            <select class="form-select ms-3 form-select-sm" name="note_template_select" id="note_template_select" value={note_template_select} onChange={handleNoteTemplateChange}>
                                    <option value="">Select Note Template</option>
                                    {note_template.map((item) => (
                                    <option value={item.note_title}>{item.note_title}</option>
                                    ))}
                                </select>
                            </div>
                            <div class="ms-auto me-3">
                                <button onClick={sendNoteEmail} class="btn btn-sm btn_secondary text-white ms-md-4"><i class="bi bi-send"></i>{loading?' Sending...':' Send Note'}</button>
                            </div>
                        </div>
                    </div>

                    <h3 class="vm_font_bold ps-3 pt-2">Note History</h3>


                    <div class="content">
                    <div class="row pt-3" style={{padding:"10px"}}>
                    <div class="col-md-8 form-group" >
                 
                                  <div class="d-flex input_wrapper" >
                                  <div ><label>Show   <select name="example_length" aria-controls="example" value={perPage} onChange={(e) => {setCurrentPage(0);setPerPage(Number(e.target.value))}}>
                                      <option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option>
                                    </select>  entries</label>
                                    </div>
                                  </div>
                              </div>
  
                              <div class="col-md-4 form-group">
                                  <div class="d-flex input_wrapper">
                                  
                                      <input class="form-control" name="searchQuery" id="searchQuery" placeholder="Search..." type="text" 
                                      value={searchQuery} onChange={(e) => {setSearchQuery(e.target.value)}}/>
                                  </div>
                              </div>
                              </div>


                    <div class="activity_wrapper pb-5 mb-5">
    

                    {filteredNoteHistory.length==0?
                <p style={{ textAlign: "center" }}>No records found</p>
                :
(filteredNoteHistory.slice((currentPage * perPage), ((currentPage * perPage) + perPage)).map((item, index) => (
                    <div class="activity_wrapper pb-3 mb-1">
                        <div class="row pt-1">
                            <div class="col-md-4 col-4 d-flex justify-content-center align-items-center pe-md-0">
                                <div class="border-bottom col"></div>
                                <div class="vm_bg_light_yellow px-2 py-1 text-center">{formatDate(item.created_at)}</div>
                                <div class="border-bottom col"></div>
                            </div>
                            <div class="col-md-8 col-8 vm_bg_light_yellow p-2">
                                <p class="vm_font_bold">Subject | {item.subject}</p>
                                <p>{getDayName(item.created_at)} at {formatTime(item.created_at)}</p>
                            </div>
                           
                        </div>                    
                    </div>
                    )))}
<div class="col-md-4" style={{ float: "left", padding: "20px 0px 0px 10px" }}>
                                        {filteredNoteHistory.length > 0 && `Showing 1 to ${Math.min(perPage, filteredNoteHistory.length)} of ${noteLogById.length} entries`}
                                    </div><div class="col-md-8" style={{ float: "right" }}>

                                        <ReactPaginate style={{ float: "right" }}
                                            previousLabel={"< Prev"}
                                            nextLabel={"Next >"}
                                            breakLabel={'...'}
                                            pageCount={Math.ceil(filteredNoteHistory.length / perPage)}
                                            onPageChange={handlePageChange}
                                            containerClassName={"pagination pagination-sm justify-content-center custom-pagination"} // Bootstrap pagination class
                                            disabledClassName={"disabled"}
                                            breakClassName={['page-item']}
                                            breakLinkClassName={'page-link'}
                                            pageClassName={'page-item'}
                                            pageLinkClassName={'page-link'}
                                            previousClassName={'page-item'}
                                            previousLinkClassName={'page-link'}
                                            nextClassName={'page-item'}
                                            nextLinkClassName={'page-link'}
                                            activeClassName={['active']} />


                                    </div>

                  
                


</div>

                    </div>
                
        
       </div>}
      {uploadVisible && <div id="uploadDiv">
        
      <div class="py-4 mt-4 border-top bg-light">

      <div class="form-group col-3 pb-4 px-0.5">
      <select class="form-select ms-3 form-select-sm" name="upload_template_select" id="upload_template_select" value={upload_template_select} onChange={handleUploadTemplateChange}>
                              <option value="">Select Category</option>
                              {upload_template.map((item) => (
                              <option value={item.upload_title}>{item.upload_title}</option>
                              ))}
                          </select>
</div>
<div class="form-group pb-4 px-3">
                      <label for="upload_subject">Subject</label>
                      <input class="mh-100 form-control" name="upload_subject" id="upload_subject" cols="100%" value={upload_subject}
                      onChange={(event) =>{ setUpload_subject(event.target.value); }} placeholder="Enter Subject"/>
                       <span  class="text-danger">{uploadErrorList.upload_subject}</span>
                  </div>

                  <div class="form-group pb-3 px-3">
                      <label for="note_message">Upload Message</label>
                      <textarea class="mh-100 form-control" name="upload_message" id="upload_message" cols="100%" value={upload_message}
                      onChange={(event) =>{ setUpload_message(event.target.value); }} rows="3" placeholder="Enter Upload Message"></textarea>
                       <span  class="text-danger">{uploadErrorList.upload_message}</span>
                  </div>
           
                      <div class="d-flex pb-3 px-3 align-items-center justify-content-between">
                            <div  >
                                {/* <label class="vm_cursor_pointer" for="image"><i class="bi bi-card-image" style={{fontSize: '2rem'}} ></i></label> */}
                                <label class="vm_cursor_pointer border border-1 d-flex align-items-center px-2" for="image" style={{width:"max-content"}}>
                                    <i class="bi bi-upload  h3 me-2"></i>
                                    Upload Files
                                </label>
                                <input class="d-none" type="file" name="image" id="image" multiple onChange= {onSelectImages}/>
                     
 <div>
                            </div>


                      </div>


                      <div class="ms-auto me-3">
                          <button onClick={uploadDocuments}  class="btn btn-sm btn_secondary text-white ms-md-4"><i class="bi bi-send"></i>{loading?' Uploading...':' Upload'}</button>
                      </div>


                      </div>
                      <div className="container"  style={{ maxHeight: "200px", overflowY: "auto", display: "flex" , padding:"15px"}}>
                        { previews.length > 0 && previews.map((preview, index) => (
                                                <div key={index} style={{ position: "relative", marginRight: "5px", marginBottom: "8px", height: "75px", width: "75px" }}  >
                                                   <span  style={{position:"absolute",cursor: "pointer",top: "-2px",right: "2px", fontSize: "20px", color:"limegreen"}}
                                                   className="delete" onClick={() => deleteImage(index)}>&times;</span>
                                                {preview.type === 'image' && (
                                                <img
                                                    loading="lazy"
                                                    onClick={() => handleImageClick(index)}
                                                    style={{ width: "100%", height: "100%", borderRadius: "5px" }}
                                                    src={preview.url}
                                                    alt={`Preview ${index}`}
                                                />
                                            )}
                                    
                                    {preview.type === 'pdf' && (
                                                
                                                    <img src="/img/pdf_icon.jpg" alt={`Preview ${index}`}  onClick={() => handlePdfClick(index)} 
                                                    style={{ width: "100%", height: "100%", borderRadius: "5px" }}/>
                                                
                                             
                                            )}
                                    
                                    {/* {preview.type === 'video' && (
                                             
                                                <video  src={preview.url} alt={`Preview ${index}`}  onClick={() => handleVideoClick(index)} 
                                                style={{ width: "100%", height: "100%", borderRadius: "5px" }}/>
                                           
                                        )} */}
                                                </div>
                                            ))}
                                        </div>
                        

                 
              </div>

            <h3 class="vm_font_bold ps-3 pt-2">Uploaded Documents</h3>
            <ul className="nav nav-tabs" id="messageTabs" role="tablist">
          <li className="nav-item" role="presentation">
            <button className={`nav-link ${activeTab === 'documents' ? 'active' : ''}`} id="documents-tab" data-bs-toggle="tab" data-bs-target="#documents" type="button" role="tab" aria-controls="documents"  aria-selected={activeTab === 'documents' ? 'true' : 'false'} onClick={() => setActiveTab('documents')}>Documents</button>
          </li>
            
            
          <li className="nav-item" role="presentation">
            <button className={`nav-link ${activeTab === 'timeline' ? 'active' : ''}`} id="timeline-tab" data-bs-toggle="tab" data-bs-target="#timeline" type="button" role="tab" aria-controls="timeline"   aria-selected={activeTab === 'timeline' ? 'true' : 'false'} onClick={() => setActiveTab('timeline')}>Timeline</button>
          </li>
            
        </ul>     

        <div className="tab-content" id="messageTabsContent">
          <div className={`tab-pane fade ${activeTab === 'documents' ? 'show active' : ''}`} id="documents" role="tabpanel" aria-labelledby="documents-tab">
         

          <div class="row pt-4 d-flex justify-content-center px-4">
                        <h4 class="text-center vm_font_bold pb-2 text-uppercase">Images</h4>
                        
                        {filteredUploadHistory.length==0?
          <p style={{ textAlign: "center" }}>No records found</p>
          :
(filteredUploadHistory.slice((currentPage * perPage), ((currentPage * perPage) + perPage)).map((item, index) => (              
                     <>{item.document_path ? (
            item.document_path.split(',').map((path, index) => (
               
                isImage(path.trim()) && (
             
                    <div key={index} class="col-md-4 col-6 pb-3 position-relative">
                      {(isUploadImageLoading && item.document_path) ? <img class="w-100" onLoad={handleUploadImageLoad} src={placeholderImage} />
                  :  <img class="w-100" onClick={() => uploadImageClick(`${LARAVEL_API_URL}/${path.trim()}`)}  src={path?`${LARAVEL_API_URL}/${path.trim()}`:placeholderImage} />
                }
                </div>
                  
                )              
            ))
        ) : 'No records found'}</>
)))}

                       
                    </div>
         
          <div class="row pt-4 d-flex justify-content-center px-4 pb-3">
                        <h4 class="text-center vm_font_bold pb-2 text-uppercase">Documents</h4>
                       
                        {filteredUploadHistory.length==0?
          <p style={{ textAlign: "center" }}>No records found</p>
          :
(filteredUploadHistory.slice((currentPage * perPage), ((currentPage * perPage) + perPage)).map((item, index) => (              
                      <p class="vm_font_bold">{item.document_path ? (
            item.document_path.split(',').map((path, index) => (
               
                isDocument(path.trim()) && (
                <span key={index}>
                    <div class="d-flex align-items-center border-bottom pb-2 mb-2">   
                      
                   {/* <label for="checkbox1" class="d-flex align-items-center ps-2"> */}
                  <i class="bi bi-file-earmark-pdf-fill fs-5"></i>
                  <a  class="ps-2" href={`${LARAVEL_API_URL}/${path.trim()}`} target="_blank">{` ${path.trim().split('/').pop()}`}</a>
                  {/* <p class="ps-2">Document Name 1</p> */}
              {/* </label> */}
                
                </div>
                    </span>
                )              
            ))
        ) : 'No records found'}</p>
)))}  
                        
  
                       
                    </div>
</div>


          <div className={`tab-pane fade ${activeTab === 'timeline' ? 'show active' : ''}`} id="timeline" role="tabpanel" aria-labelledby="timeline-tab">

          <div class="content">
              <div class="row pt-3" style={{padding:"10px"}}>
              <div class="col-md-8 form-group" >
           
                            <div class="d-flex input_wrapper" >
                            <div ><label>Show   <select name="example_length" aria-controls="example" value={perPage} onChange={(e) => {setCurrentPage(0);setPerPage(Number(e.target.value))}}>
                                <option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option>
                              </select>  entries</label>
                              </div>
                            </div>
                        </div>

                        <div class="col-md-4 form-group">
                            <div class="d-flex input_wrapper">
                            
                                <input class="form-control" name="searchQuery" id="searchQuery" placeholder="Search..." type="text" 
                                value={searchQuery} onChange={(e) => {setSearchQuery(e.target.value)}}/>
                            </div>
                        </div>
                        </div>


              <div class="activity_wrapper pb-5 mb-5">


              {filteredUploadHistory.length==0?
          <p style={{ textAlign: "center" }}>No records found</p>
          :
(filteredUploadHistory.slice((currentPage * perPage), ((currentPage * perPage) + perPage)).map((item, index) => (
              <div class="activity_wrapper pb-3 mb-1">
                  <div class="row pt-1">
                      <div class="col-md-4 col-4 d-flex justify-content-center align-items-center pe-md-0">
                          <div class="border-bottom col"></div>
                          <div class="vm_bg_light_pink px-2 py-1 text-center">{formatDate(item.created_at)}</div>
                          <div class="border-bottom col"></div>
                      </div>
                      
                      <div class="col-md-8 col-8 vm_bg_light_pink p-2">
                      <p class="vm_font_bold"> {item.subject} - {item.document_path ? (
            item.document_path.split(',').map((path, index) => (
                <span key={index}>
                    {` ${path.trim().split('/').pop()}`}
                    {/* <a style={{ color: 'gray', textDecoration: 'underline' }}>
                        {item.document_path.split(',').length > 1 ?   `View document ${index + 1}` : 'View document'}
                    </a> */}
                    {index !== item.document_path.split(',').length - 1 && ', '}
                </span>
            ))
        ) : 'No records found'}</p>
                          <p>{getDayName(item.created_at)} at {formatTime(item.created_at)}</p>
                      </div>
                     
                  </div>                    
              </div>
              )))}
<div class="col-md-4" style={{ float: "left", padding: "20px 0px 0px 10px" }}>
                                  {filteredUploadHistory.length > 0 && `Showing 1 to ${Math.min(perPage, filteredUploadHistory.length)} of ${uploadLogById.length} entries`}
                              </div><div class="col-md-8" style={{ float: "right" }}>

                                  <ReactPaginate style={{ float: "right" }}
                                      previousLabel={"< Prev"}
                                      nextLabel={"Next >"}
                                      breakLabel={'...'}
                                      pageCount={Math.ceil(filteredUploadHistory.length / perPage)}
                                      onPageChange={handlePageChange}
                                      containerClassName={"pagination pagination-sm justify-content-center custom-pagination"} // Bootstrap pagination class
                                      disabledClassName={"disabled"}
                                      breakClassName={['page-item']}
                                      breakLinkClassName={'page-link'}
                                      pageClassName={'page-item'}
                                      pageLinkClassName={'page-link'}
                                      previousClassName={'page-item'}
                                      previousLinkClassName={'page-link'}
                                      nextClassName={'page-item'}
                                      nextLinkClassName={'page-link'}
                                      activeClassName={['active']} />


                              </div>

</div>

              </div>


</div>
</div>
        
        
        
        </div>}




{isSearch && 

<div>
<a id="result"></a>
                    <div class="bg-light px-3 pt-3 mt-3">
                   
                        <h4 class="pb-3 text-center"> <label class="checkbox-inline" style={{float:"left"}}>
    <input type="checkbox"  ref={selectAllCheckboxRef}  onChange={handleSelectAll} /> Select All
  </label>
                        Search Results - {filteredInventory.length}</h4>
                       
                        {filteredInventory.map(function fn(item) {

return (
    
    <>
    <div key={item.id}>
                        <div class="search_result">
                            <div>
                                <input class="d-none" type="checkbox" name="vehicle_check" onChange={(e) => handleRowSelect(e, item.id)} id={`v${item.id}`} value={`v${item.id}`}  checked={selectedRows.includes(item.id)} />
                                <label class="shadow-lg mb-3 w-100 d-flex" for={`v${item.id}`}>
                                    <div class="position-relative p-3">
                                        {selectedRows.includes(item.id) ? <div class="vehicle_checked_wrapper w-100 h-100 position-absolute top-0 start-0 d-flex justify-content-center align-items-center" style={{background:"rgba(5,150,24,.4)"}}>
                                            <i class="bi bi-check-circle text-white display-3"></i>
                                        </div>:null}
                                      
                           
                                        <div class="row pb-md-3">
                                            <div class="col-md-2 col-4 d-flex align-items-start pe-md-0 pb-3 pb-md-0">
                                                <img class="w-100" src="https://parkers-images.bauersecure.com/wp-images/17053/930x620/00-parkers-best-new-cars-coming-2023-2024-polestar-4-lead-alt.jpg" alt=""/>
                                            </div>
                                            <div class="col-md-2 col-4 d-flex align-items-start pe-md-0 pb-3 pb-md-0">
                                                <img class="w-100" src="https://parkers-images.bauersecure.com/wp-images/17053/930x620/00-parkers-best-new-cars-coming-2023-2024-polestar-4-lead-alt.jpg" alt=""/>
                                            </div>
                                            <div class="col-md-2 col-4 d-flex align-items-start pe-md-0 pb-3 pb-md-0">
                                                <img class="w-100" src="https://parkers-images.bauersecure.co+m/wp-images/17053/930x620/00-parkers-best-new-cars-coming-2023-2024-polestar-4-lead-alt.jpg" alt=""/>
                                            </div>
                                            <div class="col-md-2 col-4 d-flex align-items-start pe-md-0 pb-3 pb-md-0">
                                                <img class="w-100" src="https://parkers-images.bauersecure.com/wp-images/17053/930x620/00-parkers-best-new-cars-coming-2023-2024-polestar-4-lead-alt.jpg" alt=""/>
                                            </div>
                                            <div class="col-md-2 col-4 d-flex align-items-start pe-md-0">
                                                <img class="w-100" src="https://parkers-images.bauersecure.com/wp-images/17053/930x620/00-parkers-best-new-cars-coming-2023-2024-polestar-4-lead-alt.jpg" alt=""/>
                                            </div>
                                        </div>
                                        <div>
                                            <h3 class="vm_font_semi_bold lh-base pb-1 border-bottom mb-2">  {item.vehicle_name} {item.trim} {item.drive_type}</h3>
                                            <div class="d-md-flex justify-content-between">
                                                <p class="lh-1"><i class="bi bi-tags fs-6"></i> <span class="vm_font_semi_bold vm_text_color_secondary fs-6"><span class="currency">CAD</span>{item.price.sale_price.toLocaleString()}</span> + Tax & Lic</p>
                                                <p class="lh-1 pt-2 pt-md-0"><span class="vm_font_semi_bold"><i class="bi bi-speedometer me-1"></i> Milage</span> : {item.odometer.toLocaleString()} Km</p>
                                            </div>
                                            <p class="text-muted pt-1">7.99% for 84 Months</p>
                                            <div class="row mt-2 border-top pb-2">
                                                <div class="d-flex border-bottom">
                                                    <div class="col p-1 border-end vm_font_bold">Body Style:</div>
                                                    <div class="col p-1 ps-3">{item.body_type}</div>
                                                </div>
                                                <div class="d-flex border-bottom">
                                                    <div class="col p-1 border-end vm_font_bold">Engine:</div>
                                                    <div class="col p-1 ps-3">{item.engine_type}</div>
                                                </div>
                                                <div class="d-flex border-bottom">
                                                    <div class="col p-1 border-end vm_font_bold">Exterior Colour:</div>
                                                    <div class="col p-1 ps-3">{item.exterior_color}</div>
                                                </div>
                                                <div class="d-flex border-bottom">
                                                    <div class="col p-1 border-end vm_font_bold">Transmission:</div>
                                                    <div class="col p-1 ps-3">{item.transmission}</div>
                                                </div>
                                                <div class="d-flex border-bottom">
                                                    <div class="col p-1 border-end vm_font_bold">Drive Train:</div>
                                                    <div class="col p-1 ps-3">{item.drive_type}</div>
                                                </div>
                                                <div class="d-flex border-bottom">
                                                    <div class="col p-1 border-end vm_font_bold">Stock #:</div>
                                                    <div class="col p-1 ps-3">{item.stock_no}</div>
                                                </div>
                                            </div>
                                            <Link to={`/inventory-details/${item.id}`} target="_blank">
                                                <button class="btn btn-sm btn_secondary">View More</button>
                                                </Link>
                                        </div>
                                    </div>
                                </label>
                            </div>
                            </div>
                            </div>


                            </>
);
})}
                            <div class="d-flex justify-content-center align-items-center pt-2 pb-5 mb-5 send_option_wrapper">
                         
                            <button onClick={handleGenerate} class="btn btn-sm btn_secondary text-white" ><i class="bi bi-send"></i> {loading?"Generating...":"Generate"}</button>
                        </div>

                        </div>
                      
</div>
}

{inqueryVisible && 
            <>
            {/* <div class="border vm_bg_secondary p-2 d-flex justify-content-between align-items-center shadow-lg vm_cursor_pointer mx-3 my-3" data-bs-target="#exampleModalToggle" data-bs-toggle="modal">
                <h5 class="text-white text-uppercase vm_font_bold ps-2">Search Inventory</h5>
                <div class="border-start ps-3">
                    <i class="bi bi-search h1 pe-2 text-white"></i>
                </div>
            </div> */}
            <h3 class="vm_font_bold mt-4 ps-3">Leads Inquery</h3><div class="px-3 pb-5 mb-5">
                {inquiryById.length==0?
                <p style={{ textAlign: "center" }}>No records found</p>
                :

(inquiryById.map((item, index) => (  


                    <div class="bg-white px-3 py-3 shadow-lg mb-3 w-100" for="car4">
                        
                        <div>
                            <p><span class="vm_font_bold"><i class="bi bi-calendar-week me-1"></i> Inquery Date</span> : {formatDate(item.created_at)}
                            {/* <button class="btn btn_secondary" style={{float:"right"}} onClick={() => openSearchPopup(item)}>Search </button> */}
                            </p>
                            {/* <h3 class="vm_font_semi_bold lh-base pb-1 border-bottom mb-2">{item.vehicle_name}</h3> */}
                            
                            {/* <div class="d-md-flex justify-content-between">
                                <p class="lh-1"><span class="vm_font_semi_bold">Price Range</span> :
                                    <span class="vm_font_semi_bold vm_text_color_secondary">
                                        <span class="currency"> CAD</span> {item.min_price && item.min_price.toLocaleString()} - 
                                        <span class="currency"> CAD</span> {item.max_price && item.max_price.toLocaleString()}
                                    </span>
                                </p>
                                <p class="lh-1 pt-2 pt-md-0"><span class="vm_font_semi_bold"><i class="bi bi-speedometer"></i> Milage</span> : {item.min_km && item.min_km.toLocaleString()} km - {item.max_km && item.max_km.toLocaleString()} Km</p>
                            </div> */}

                            <div class="row mt-2 bg-white border-top">
                            <div class="d-flex border-bottom">
                                    <div class="col p-1 border-end vm_font_bold">Unit Name:</div>
                                    <div class="col p-1 ps-3">{item.vehicle_name}</div>
                                </div>
                                <div class="d-flex border-bottom">
                                   <div class="col p-1 border-end vm_font_bold">Message:</div>
                                    <div class="col p-1 ps-3">{item.description}</div>
                                </div>
                                {/* <div class="d-flex border-bottom">
                                    <div class="col p-1 border-end vm_font_bold">Body Style:</div>
                                    <div class="col p-1 ps-3">{item.body_type}</div>
                                </div>
                                <div class="d-flex border-bottom">
                                    <div class="col p-1 border-end vm_font_bold">Engine:</div>
                                    <div class="col p-1 ps-3">{item.engine_type}</div>
                                </div>
                                <div class="d-flex border-bottom">
                                    <div class="col p-1 border-end vm_font_bold">Exterior Colour:</div>
                                    <div class="col p-1 ps-3">{item.exterior_color}</div>
                                </div>
                                <div class="d-flex border-bottom">
                                    <div class="col p-1 border-end vm_font_bold">Transmission:</div>
                                    <div class="col p-1 ps-3">{item.transmissions}</div>
                                </div>
                                <div class="d-flex border-bottom">
                                    <div class="col p-1 border-end vm_font_bold">Drive Train:</div>
                                    <div class="col p-1 ps-3">{item.drive_type}</div>
                                </div>
                                <div class="d-flex border-bottom">
                                    <div class="col p-1 border-end vm_font_bold">Doors:</div>
                                    <div class="col p-1 ps-3">{item.doors}</div>
                                </div>
                                <div class="d-flex border-bottom">
                                    <div class="col p-1 border-end vm_font_bold">Stock #:</div>
                                    <div class="col p-1 ps-3">{item.stock_no}</div>
                                </div> */}
                            </div>
                        </div>
                    </div>

))
)}

                </div></>

}
           



                    

<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title vm_font_bold text-uppercase" id="exampleModalLabel">Suggested Listing By Attodesk</h5>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <div class="search_result">
                                        {filteredInventory.filter(section => selectedRows.includes(section.id)).map(function fn(item) {

return (
    <>  
    <div key={item.id}>
                                            <div>
                                           

                                                <label class="shadow-lg mb-3 w-100 d-flex">
                                                    <div class="position-relative p-3">
                                                        {/* <div class="vehicle_checked_wrapper w-100 h-100 position-absolute top-0 start-0 d-flex justify-content-center align-items-center">
                                                            <i class="bi bi-check--circle text-white display-3"></i>
                                                        </div> */}
                                                        <div class="row pb-md-3">
                                                            <div class="col-md-2 col-4 d-flex align-items-start pe-md-0 pb-3 pb-md-0">
                                                                <img class="w-100" src="https://parkers-images.bauersecure.com/wp-images/17053/930x620/00-parkers-best-new-cars-coming-2023-2024-polestar-4-lead-alt.jpg" alt=""/>
                                                            </div>
                                                            <div class="col-md-2 col-4 d-flex align-items-start pe-md-0 pb-3 pb-md-0">
                                                                <img class="w-100" src="https://parkers-images.bauersecure.com/wp-images/17053/930x620/00-parkers-best-new-cars-coming-2023-2024-polestar-4-lead-alt.jpg" alt=""/>
                                                            </div>
                                                            <div class="col-md-2 col-4 d-flex align-items-start pe-md-0 pb-3 pb-md-0">
                                                                <img class="w-100" src="https://parkers-images.bauersecure.com/wp-images/17053/930x620/00-parkers-best-new-cars-coming-2023-2024-polestar-4-lead-alt.jpg" alt=""/>
                                                            </div>
                                                            <div class="col-md-2 col-4 d-flex align-items-start pe-md-0 pb-3 pb-md-0">
                                                                <img class="w-100" src="https://parkers-images.bauersecure.com/wp-images/17053/930x620/00-parkers-best-new-cars-coming-2023-2024-polestar-4-lead-alt.jpg" alt=""/>
                                                            </div>
                                                            <div class="col-md-2 col-4 d-flex align-items-start pe-md-0">
                                                                <img class="w-100" src="https://parkers-images.bauersecure.com/wp-images/17053/930x620/00-parkers-best-new-cars-coming-2023-2024-polestar-4-lead-alt.jpg" alt=""/>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <h3 class="vm_font_semi_bold lh-base pb-1 border-bottom mb-2">  {item.vehicle_name} {item.trim} {item.drive_type}</h3>
                                                            <div class="d-md-flex justify-content-between">
                                                                <p class="lh-1"><i class="bi bi-tags fs-6"></i>
                                                                    <span class="vm_font_semi_bold vm_text_color_secondary fs-6">
                                                                        <span class="currency">CAD</span>{item.price.sale_price.toLocaleString()}
                                                                    </span> + Tax & Lic
                                                                </p>
                                                                <p class="lh-1 pt-2 pt-md-0"><span class="vm_font_semi_bold"><i class="bi bi-speedometer me-1"></i> Milage</span> : {item.odometer.toLocaleString()} Km</p>
                                                            </div>
                                                            <p class="text-muted pt-1">7.99% for 84 Months</p>
                                                            <div class="row mt-2 border-top pb-2">
                                                                <div class="d-flex border-bottom">
                                                                    <div class="col p-1 border-end vm_font_bold">Body Style:</div>
                                                                    <div class="col p-1 ps-3">{item.body_type}</div>
                                                                </div>
                                                                <div class="d-flex border-bottom">
                                                                    <div class="col p-1 border-end vm_font_bold">Engine:</div>
                                                                    <div class="col p-1 ps-3">{item.engine_type}</div>
                                                                </div>
                                                                <div class="6d-flex border-bottom">
                                                                    <div class="col p-1 border-end vm_font_bold">Exterior Colour:</div>
                                                                    <div class="col p-1 ps-3">{item.exterior_color}</div>
                                                                </div>
                                                                <div class="d-flex border-bottom">
                                                                    <div class="col p-1 border-end vm_font_bold">Transmission:</div>
                                                                    <div class="col p-1 ps-3">{item.transmission}</div>
                                                                </div>
                                                                <div class="d-flex border-bottom">
                                                                    <div class="col p-1 border-end vm_font_bold">Drive Train:</div>
                                                                    <div class="col p-1 ps-3">{item.drive_type}</div>
                                                                </div>
                                                                <div class="d-flex border-bottom">
                                                                    <div class="col p-1 border-end vm_font_bold">Stock #:</div>
                                                                    <div class="col p-1 ps-3">{item.stock_no}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </label>
                                            </div>
                                            </div>
                        </>
);
})}
                                        </div>




                                    </div>
                                    <div class="modal-footer send_option_wrapper" >
                                    <div class="option d-flex">
            <input class="d-none" type="checkbox" id="email_send"/>
            <label class="py-1 vm_cursor_pointer me-2 d-flex align-items-center" for="email_send" >
                <i class="bi bi-toggle-off"></i>
                <i class="bi bi-toggle-on text-success"></i>
                Email
            </label>
            <input class="d-none" type="checkbox" id="sms_send"/>
            <label class="py-1 vm_cursor_pointer me-2 d-flex align-items-center" for="sms_send">
                <i class="bi bi-toggle-off"></i>
                <i class="bi bi-toggle-on text-success"></i>
                SMS
            </label>
        </div>
                                        {/* <div class="option d-flex">
                                            <input class="d-none" type="checkbox" id="email_send"/>
                                            <label class="py-2 vm_cursor_pointer me-2 d-flex" for="email_send">
                                                <i class="bi bi-check-lg me-1"></i> Email
                                            </label>
                                            <input class="d-none" type="checkbox" id="sms_send"/>
                                            <label class="py-2 vm_cursor_pointer me-2 d-flex" for="sms_send">
                                                <i class="bi bi-check-lg me-1"></i> SMS
                                            </label>
                                        </div> */}
                                        <button class="btn btn-sm btn_secondary text-white" data-bs-toggle="modal" data-bs-target="#exampleModal"><i class="bi bi-send"></i> Send</button>
                                    </div>
                                </div>
                            </div>
                        </div>       




        <div class="modal fade" id="exampleModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5" id="exampleModalToggleLabel">Analyse Vehicle</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                       
                            <div class="row">
                            <div class="form-group col-md-6 pb-3">
                             
                                    <label class="pb-0 mb-0 mb-2" for="vehicle_make">Make</label>
                                    <Select 
        value={selectedMakes.map(make => ({ value: make, label: make}))}
        options={makeOptions.map(make => ({ value: make, label: make }))}
        isMulti
        placeholder="Select Make"
        onChange={handleMakeChange}
        // menuPortalTarget={document.body}
        noOptionsMessage={() => 'No options available'}
      />           {makeError && <span class="text-danger">{makeError}</span>} 

                                </div>
                    
                                
                                <div class="form-group col-md-6 pb-3">
                                    <label class="pb-0 mb-0 mb-2" for="vehicle_make">Model</label>
                                    <Select
        value={selectedModels.map(model => ({ value: model, label: model}))}
        options={modelOptions.map(model => ({ value: model, label: model }))}
        isMulti
        placeholder="Select Model"
        onChange={handleModelChange}
        // menuPortalTarget={document.body}
        noOptionsMessage={() => 'No options available'}
      /> 
       {/* {modelError && <span class="text-danger">{modelError}</span>}  */}
                                </div>
                               
                                <div class="form-group col-md-6 pb-3">
                                    <label class="pb-0 mb-0" for="min_price">Min Price</label>
                                    <input type="number" class="form-control mt-2"  name="minPrice" id="minPrice"  value={minPrice} onChange={(e) => setMinPrice(e.target.value)} min="0"/>
                                </div>
                                <div class="form-group col-md-6 pb-3">
                                    <label class="pb-0 mb-0" for="max_price">Max Price</label>
                                    <input type="number" class="form-control mt-2"  name="max_price" id="max_price"  value={maxPrice} onChange={(e) => setMaxPrice(e.target.value)} min="0" />
                                </div>

                                <div class="form-group col-md-6 pb-3">                    
                                    <label class="pb-0 mb-0" for="start_km">Min KM</label>
                                    <input  type="number" class="form-control mt-2"  name="minKilometer" id="minKilometer"
            value={minKilometer} onChange={(e) => setMinKilometer(e.target.value)} min="0"/>
                                    
                                </div>
                                <div class="form-group col-md-6 pb-3">
                                    <label class="pb-0 mb-0" for="to_year">Max KM</label>
                                    <input type="number"  class="form-control mt-2"  name="maxKilometer" id="maxKilometer" 
            value={maxKilometer} onChange={(e) => setMaxKilometer(e.target.value)} min="0"/>
                                </div>
                                {/* <div class="form-group col-md-6 pb-3">
                                    <label class="pb-0 mb-0" for="from_year">From Year</label>
                                    <select class="form-select mt-2" name="from_year" id="from_year">
                                        <option value="2000">2000</option>
                                        <option value="2001">2001</option>
                                    </select>
                                </div>
                                <div class="form-group col-md-6 pb-3">
                                    <label class="pb-0 mb-0" for="to_year">To Year</label>
                                    <select class="form-select mt-2" name="to_year" id="to_year">
                                        <option value="2000">2000</option>
                                        <option value="2001">2001</option>
                                    </select>
                                </div> */}
                               
                               
{/*                                 
                                <div class="form-group col-md-6 pb-3">
                                    <label class="pb-0 mb-0" for="vehicle_doors">Doors</label>
                                    <select class="form-select mt-2" name="vehicle_doors" id="vehicle_doors">
                                        <option value="2">2</option>
                                        <option value="4">4</option>
                                    </select>
                                </div> */}
                            </div>
                       
                    </div>
                    <div class="modal-footer">
                        {/* <a href="leads_analyze_result.php#result"> */}
                            <button class="btn btn-primary" 
                             onClick={search}>{loading ? "Searching..." : "Search"}</button>
                        {/* </a> */}
                    </div>
                </div>
            </div>
        </div>








        
    </div></>
            
))}

                <a id="contact_history" class="a_id_contact_history position-relative"></a>
                <div class="activity bg-white">
                    
<div class="d-flex justify-content-between">
    <div class="main_title bg-white border-bottom w-100">
        <h1><i class="bi bi-card-checklist"></i>Activity History</h1>
        <div class="dropdown">
            <i class="bi bi-three-dots-vertical vm_cursor_pointer me-0" data-bs-toggle="dropdown" aria-expanded="false"></i>
            <ul class="dropdown-menu">
                <li><a class="dropdown-item" href="#">Action</a></li>
                <li><a class="dropdown-item" href="#">Another action</a></li>
                <li><a class="dropdown-item" href="#">Something else here</a></li>
            </ul>
        </div>
    </div>
</div>
<div class="activity_wrapper">
{inquiryById.length === 0 && emailLogById.length === 0 && smsLogById.length === 0 && noteLogById.length === 0 && meetLogById.length === 0 && uploadLogById.length ===0? (
        <p style={{ textAlign: "center", padding: "20px" }}>No Activity found</p>
    ) : (
        <>
           {([...inquiryById, ...emailLogById, ...smsLogById, ...noteLogById, ...meetLogById, ...uploadLogById]) // Merge both arrays
                .sort((a, b) => new Date(b.created_at) - new Date(a.created_at)) // Sort by date in descending order
                .map((item, index) => (
                    <div class="row pt-3" key={`activity-${index}`}>
                        <div class="col-md-4 col-4 d-flex justify-content-center align-items-center pe-md-0">
                            <div class="border-bottom col"></div>
                          <div class={`${emailLogById.includes(item) ? 'vm_bg_light_red':(inquiryById.includes(item) ? 'vm_bg_light_orange': (smsLogById.includes(item) ? 'vm_bg_light_blue' : (meetLogById.includes(item) ? 'vm_bg_light_green' : (uploadLogById.includes(item) ? 'vm_bg_light_pink' : 'vm_bg_light_yellow'))))} px-2 py-1 text-center`}>
                            {/* <div class="vm_bg_light_red px-2 py-1 text-center"> */}
                                {formatDate(item.created_at)} </div>
                            <div class="border-bottom col"></div>
                        </div>
                        <div class={`col-md-8 col-8 ${emailLogById.includes(item) ? 'vm_bg_light_red' :(inquiryById.includes(item) ? 'vm_bg_light_orange': (smsLogById.includes(item) ? 'vm_bg_light_blue'  : (meetLogById.includes(item) ? 'vm_bg_light_green' : (uploadLogById.includes(item) ? 'vm_bg_light_pink' : 'vm_bg_light_yellow'))))} p-2`}>
                        <p class="vm_font_bold">   {emailLogById.includes(item) ? 'Email sent' : (inquiryById.includes(item) ? 'Inquiry submitted' : (smsLogById.includes(item) ? 'SMS sent' : (meetLogById.includes(item) ? 'Appointment email sent' : (uploadLogById.includes(item) ? 'Document uploaded' : 'Note email sent'))))}</p>
                            <p>{getDayName(item.created_at)} at {moment(item.created_at).format('hh:mm A')}</p>
                        </div>
                    </div>
                ))}
        </>
    )}
    <div class="py-5"></div>
    <div class="py-3"></div>
</div>
        </div>
    </div>



    

              
               
</div>
          
           
</div>
</div>

          
           

    );
}
export default LeadsDetails;