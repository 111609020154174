import SideNav from "./SideNav";
import TopBar from "./TopBar";
import { useState, useEffect, useRef } from "react";
import axios from 'axios';
import {Link, useParams, useNavigate} from "react-router-dom";
import ReactPaginate from 'react-paginate';
import Swal from "sweetalert2";
import Select from "react-select";

function EmailLogs(){

    const API_URL = process.env.REACT_APP_API_URL;
    const LARAVEL_API_URL = process.env.REACT_APP_LARAVEL_API;

    const [isLoading, setIsLoading] = useState(true);
    const [email_logs, setEmail_logs] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [perPage, setPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [message, setMessage] = useState('');
    const [email, setEmail] = useState("");
    const [bcc, setBcc] = useState("");
    const [subject, setSubject] = useState("");
    const [email_error_list, setEmailErrorList] = useState([]);
    const editorContainer = useRef(null);
    const isEditorInitialized = useRef(false); // Track initialization
    const [template, setTemplate] = useState("");
    const [email_template, setEmail_template] = useState([]);    
    const [template_details, setTemplate_details] = useState([]);
    const [previews, setPreviews] = useState([]);
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [videos, setVideos] = useState([]);
    const [previewsVideo, setPreviewsVideo] = useState([]);
    const [successMessage, setSuccessMessage] = useState('');
    const [selectedEmails, setSelectedEmails] = useState([]);
    const [label, setLabel] = useState([]);    

    const filteredResult = email_logs.filter((item) => {
        return item.to.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.subject.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.created_at.toLowerCase().includes(searchQuery.toLowerCase()) 
        
      });

   
      useEffect(() => {
        const loadUnlayerScript = () => {
          return new Promise((resolve, reject) => {
            // Check if the script is already loaded
            if (document.querySelector('script[src="https://editor.unlayer.com/embed.js"]')) {
              resolve();
              return;
            }
            const script = document.createElement('script');
            script.src = 'https://editor.unlayer.com/embed.js';
            script.onload = resolve;
            script.onerror = reject;
            document.body.appendChild(script);
          });
        };
      
        loadUnlayerScript()
          .then(() => {
            // Initialize Unlayer editor only once
            if (!isEditorInitialized.current && editorContainer.current) {
              window.unlayer.init({
                id: editorContainer.current.id,
                displayMode: 'email',
              });
      
              isEditorInitialized.current = true; // Mark as initialized
      
              // Load saved message if exists
              if (message) {
                window.unlayer.loadDesign(JSON.parse(message));
              }
             
      
              // Save the message on design changes
              window.unlayer.addEventListener('design:changed', () => {
                window.unlayer.saveDesign((design) => {
                  setMessage(JSON.stringify(design));
                });
              });
            }
          })
          .catch((error) => console.error('Failed to load Unlayer script:', error));
      
        return () => {
          // Clean up event listeners
          if (isEditorInitialized.current) {
            window.unlayer.removeEventListener('design:changed');
          }
        };
      }, [message]);

      const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
      };
      const formatDate = (dateString) => {
        const createdDate = new Date(dateString);
        const options = {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        };
    
        const formattedDate = createdDate.toLocaleString('en-US', options);
       // const timeAgo = getTimeAgo(createdDate);
    
       // return `${formattedDate} (${timeAgo})`;
        return `${formattedDate}`;
      };
    

      const handleTemplateChange  = (event) => {
        setTemplate(event.target.value);
        if(event.target.value){
            const template_details = email_template.find(item=> item.template_name === event.target.value);
            setTemplate_details(template_details);
            setSubject(template_details.subject);
            // setMessage(template_details.message);
            const parsedMessage = template_details.message ? JSON.parse(template_details.message) : {};
            setMessage(parsedMessage);

          
    // Re-initialize Unlayer if it's not initialized
    if (window.unlayer && !window.unlayerInitialized) {
         const unlayerInstance = window.unlayer;
  
  
    //     // Load existing design into the editor
        if (parsedMessage) {
          unlayerInstance.loadDesign(parsedMessage);
        }
  
      
                
           }

           
            if (template_details.image_path) {

                const filepathArray = template_details.image_path.split(',');
                const imageUrlArray = filepathArray.map(filepath => `${LARAVEL_API_URL}/${filepath}`);
                setPreviews(imageUrlArray);
    
                const initialPreviewsPromises = imageUrlArray.map(async filepath => {
                    const filename = filepath.substring(filepath.lastIndexOf('/') + 1); // Extract filename from URL
                    const fileType = filename.split('.').pop().toLowerCase(); // Extract file extension and convert to lowercase
                
                    if (fileType === 'pdf') {
                        // For PDF files
                        try {
                            const response = await fetch(filepath);
                            const blob = await response.blob();
                            return new File([blob], filename, { type: 'application/pdf' });
                        } catch (error) {
                            console.error('Error fetching PDF file:', error);
                            return null;
                        }
                    } else if (['mp4', 'webm', 'ogg'].includes(fileType)) {
                        try {
                            const response = await fetch(filepath);
                            const blob = await response.blob();
                            return new File([blob], filename, { type: `video/${fileType}` });
                        } catch (error) {
                            console.error('Error fetching video:', error);
                            return null;
                        }
                    } else {
                        // For image files
                        try {
                            const response = await fetch(filepath);
                            const blob = await response.blob();
                            return new File([blob], filename, { type: `image/${fileType}` });
                        } catch (error) {
                            console.error('Error fetching image:', error);
                            return null;
                        }
                    }
                });
                
                Promise.all(initialPreviewsPromises)
                    .then(initialPreviews => {
                        // Filter out null values (in case of error)
                        const filteredPreviews = initialPreviews.filter(preview => preview !== null);
                        setImages(filteredPreviews);
                      //  console.log(filteredPreviews)
                    })
                    .catch(error => {
                        console.error('Error creating initial previews:', error);
                        setImages([]); // Set empty array in case of error
                    });
                
                const updatedPreviews = imageUrlArray.map(url => {
                    const fileType = getFileType(url);
                    return { type: fileType, url: url };
                });
                setPreviews(updatedPreviews);
            } 
            // if (template_details.video_path) {
            //     const filepathArray = template_details.video_path.split(',');
            //     const videoUrlArray = filepathArray.map(filepath => `${LARAVEL_API_URL}/${filepath}`);
            //     setPreviewsVideo(videoUrlArray);
    
            //     // Set videos based on preview videos
            //     const videoFiles = videoUrlArray.map(async url => {
            //         const res = await fetch(url);
            //         const blob = await res.blob();
    
            //         // Extract filename from URL
            //         const filename = url.substring(url.lastIndexOf('/') + 1);
    
            //         return new File([blob], filename, { type: "video/mp4" });
            //     });
            //     Promise.all(videoFiles).then(videos => {
            //         setVideos(videos);
            //     });
            // } else {
            //     setPreviewsVideo([]);
            //     setVideos([]);
            // }
        }
        else
        {   setTemplate(""); 
            setTemplate_details([]);
            setSubject("");
            setMessage("");
            setPreviews([]);
            setImages([]);
            window.unlayer.loadDesign({ body: { rows: [] } }); // Explicitly clear the design
            
            }
    
        };

        const getFileType = (url) => {
            const extension = url.split('.').pop().toLowerCase();
            switch (extension) {
                case 'jpg':
                case 'jpeg':
                case 'png':
                    case 'webp':
                    return 'image';
                case 'pdf':
                    return 'pdf';
                case 'mp4':
                case 'avi':
                case 'mov':
                    return 'video';
                // Add more file types as needed
                default:
                    return 'unknown';
            }
        };

        const sendEmail =  async (e) => {
            e.preventDefault();
            setLoading(true);
            try {
           
            const emailArray = selectedEmails.map((email) => email.value);
            const bccArray = bcc.split(",");
          
            let htmlOutput = "";
            if (window.unlayer) {
                htmlOutput = await new Promise((resolve) => {
                    window.unlayer.exportHtml(({ html }) => {
                        resolve(html); // Get rendered HTML
                    });
                });
            }
            const personalizeTemplate = (template, recipientName) => {
              return template.replace(/\[First Name\]/g, recipientName || "Valued Customer");
          };

                // const formattedMessage = (template_details?.message ?? '').replace(/\n/g, '<br>');
                    
            // const messageWithLogo = `<img src="https://www.attodesk.com/Auttodesk-Black.png" alt="Attodesk Logo Big" width="140" /><br><br>${formattedMessage}`;
            for (const selected of selectedEmails) {
              const recipientEmail = selected.value;
              const recipientName = selected.firstName; // Get the customer's first name
              const personalizedMessage = personalizeTemplate(htmlOutput, recipientName);
  
            const formData = new FormData();

           // formData.append("customer_id", id);
           formData.append("to", recipientEmail.trim()); 
            formData.append("bcc", bccArray); 
            formData.append("subject", subject);
          
                // Append the design JSON as the message
                formData.append("message", personalizedMessage);
          

            // formData.append("message", message.replace(/\n/g, '<br>'));
            // if(template_details.message){
            //     formData.append("message", formattedMessage);
            // }
            // else{
            //     formData.append("message", message);
            // }
           
          
            images.forEach((image, index) => {
              formData.append(`image[${index}]`, image);
            });

           
          
            // videos.forEach((video, index) => {
            //   formData.append(`video[${index}]`, video);
            // });
          
            try {
                // Send email
                await axios.post(`${API_URL}/send-email-brevo`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });
            } catch (error) {
                console.error(`Failed to send email to ${recipientEmail}:`, error);
                // Optionally handle individual email errors
            }
        }
          
              // Show success message and reset states
              showToast("Email sent successfully!");
              setLoading(false);
              setTemplate_details([]);
              resetEmail();
              //getEmaillogbyId(id);
              setEmailErrorList([]);
            } catch (error) {
              // Handle errors
              setLoading(false);
              if (error.response?.data?.validate_err) {
                setEmailErrorList(error.response.data.validate_err);
              } else {
                console.error("Error sending email:", error);
              }
            }
          };

          const resetEmail = () => {
            setTemplate([]);
            setEmail("");
            setSelectedEmails([]);
            setSubject("");
            setMessage("");
            window.unlayer.loadDesign({ body: { rows: [] } });
            setImages([]);
            setVideos([]);
            setPreviews([]);
            setPreviewsVideo([]);
            setSuccessMessage("");
            setLoading(false);
 
             };

             const showToast = (message) => {
                Swal.fire({
                  icon: 'success',
                  title: message,
                  color:"white",
                  toast: true,
                  position: 'top-end',
                  showConfirmButton: false,
                  timer: 3500,
                  customClass: {
                      popup: 'sweetalert-popup',
                      title: 'sweetalert-title',
                      icon: 'sweetalert-icon',
                    },
                    background: '#333',
                    showCloseButton: true,
                    padding: '4px', 
                });
              };
   
      const getTimeAgo = (date) => {
        const now = new Date();
        const diffInMilliseconds = now - date;
        const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));
    
        if (diffInMinutes < 60) {
            return `${diffInMinutes} minutes ago`;
        } else if (diffInMinutes < 1440) { // 1440 minutes = 24 hours
            const diffInHours = Math.floor(diffInMinutes / 60);
            return `${diffInHours} hours ago`;
        } else if (diffInMinutes <= 21600) { // 21600 minutes = 15 days
            const diffInDays = Math.floor(diffInMinutes / 1440);
            return `${diffInDays} day${diffInDays > 1 ? 's' : ''} ago`;
        } else {
            return ''.replace(/[()]/g, ) ;
        }
    };


      useEffect(() => {
        setIsLoading(true);
        getEmailTemplateList();
        getEmailLogsList();
        getCustomerList();
        getLabelList();
       
    }, []);

    function getLabelList()
    {    
      axios.get(`${API_URL}/view-label`).then(res=>{
        if (res.data.status === 200) {
          const formattedLabels = res.data.label.map((label) => ({
            value: label.id,
            label: label.label_name,
            type: 'label',
            customer_id: label.customer_id ? JSON.parse(label.customer_id) : [],
          }));
          setLabel(formattedLabels);
        
        } else if (res.data.status === 404) {
          setLabel([]);
        }
      })
      .catch((err) => {
        console.error("Error fetching labels:", err);
      });
  };

    function getEmailTemplateList()
    {    
      axios.get(`${API_URL}/view-email-template`).then(res=>{
          if(res.data.status === 200){
              setEmail_template(res.data.emailTemplate);       
          }     
          else if(res.data.status === 404){
            setEmail_template([]);
             // setMessage(res.data.message);     
              }             
       });    
    }
    function getEmailLogsList()
    {
      
      axios.get(`${API_URL}/view-email-logs`).then(res=>{
          if(res.data.status === 200){
              setEmail_logs(res.data.emailLogs); 
             
          }    
          else if(res.data.status === 404){
              setMessage(res.data.message);
              }
              setIsLoading(false);
       });  
     
    }
    const getCustomerList = () => {
        axios
          .get(`${API_URL}/view-customer`)
          .then((res) => {
            if (res.data.status === 200) {
              const formattedCustomers = res.data.customer.map((customer) => ({
                value: customer.email,
                label: customer.name || customer.email, // Use name if available, otherwise email
                firstName: customer.first_name || "Valued Customer",
                id: customer.id
              }));
              setCustomers([{ value: 'select-all', label: 'Select All' }, ...formattedCustomers]);
            } else if (res.data.status === 404) {
             console.error('No customers found');
            }
            setIsLoading(false);
          })
          .catch((err) => {
            console.error('Error fetching customers:', err);
            setIsLoading(false);
          });
      };
    
      const handleEmailSelection = (selectedOptions) => {
      
        if (selectedOptions?.some((option) => option.value === 'select-all')) {
          // If "Select All" is selected, set all customers
          const allEmails = customers.filter((option) => option.value !== 'select-all');
          setSelectedEmails(allEmails);
        }
        else if (selectedOptions?.some((option) => option.type === 'label')) {
          // Handle label selection
          const selectedLabel = selectedOptions.find((option) => option.type === 'label');
          
          if (selectedLabel?.customer_id && Array.isArray(selectedLabel.customer_id)) {
            // Extract customer IDs from the selected label
            const customerIDs = selectedLabel.customer_id;
            // Filter emails from customers using the customer IDs
            const filteredEmails = customers.filter((customer) => customerIDs.includes(customer.id));
           
            // Update the state with the filtered emails
            setSelectedEmails((prev) => [...prev, ...filteredEmails]);
          } 
        }
        else {
          // Otherwise, set the selected options
          setSelectedEmails(selectedOptions || []);
        }
      };

    
      
    
    return (
<div>
<SideNav/>
 <TopBar/>
 {isLoading?
     <div className="loader-container" style ={{backgroundColor: "rgba(0, 0, 0, 0.5)", position: "fixed",top: "0",left: "0",width: "100%",height: "100%",display: "flex",justifyContent: "center",alignItems: "center",zIndex: "9999"}}>
    <div class="d-flex justify-content-center">
  <div class="spinner-border" role="status">
  </div>
  </div>
</div>:null}




<div class="right_panel position contact_section vh-100" id="right_panel">

<div class="main_title d-flex align-items-center">
    <h1><i class="bi bi-sticky"></i> Email</h1>
</div>

<div class="content_wrapper pt-0">
            <div class="vm_content_box_1">
                <div class="title">
                    <h2>Send Email</h2>
                </div>
                  <div class="content pt-3">
                    <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                                <label for="toemail">To email</label>
                                {/* <input class="form-control col" type="text" name="toemail" id="toemail" value={email} placeholder="Enter to Email"
                                onChange={(event) => { setEmail(event.target.value); }}/> */}
                    
<Select
          id="toemail"
          isMulti
          //options={customers}
          options={[
             {
      label: "Emails",
      options: customers.map((customer) => ({
        value: customer.value, // Use the value set in getCustomerList
        label: customer.label, // Use the label set in getCustomerList
        type: 'email',
      })),
    },
    {
      label: "Labels",
      options: label.map((label) => ({
        value: label.value,
        label: label.label,
        type: 'label',
        customer_id: label.customer_id,
      })),
    },
          ]}
          value={selectedEmails}
          onChange={handleEmailSelection}
          placeholder="Select customer emails"
          class="form-control" 
        />
                            </div>
                        </div>

                        <div class="col-md-6">
                        <div class="form-group">
                               {/*  <label class="vm_cursor_pointer" for="image"><i class="bi bi-card-image h2"></i></label>
                                <input class="d-none" type="file" name="image" id="image" multiple onChange= {onSelectImages}/>


 

                                <label class="vm_cursor_pointer ps-2" for="video"><i class="bi bi-film h3"></i></label>
                                <input class="d-none" type="file" name="video" id="video" multiple
                             onChange= {onSelectVideos}/>

                               <div className="container"  style={{ maxHeight: "200px", overflowY: "auto", display: "flex"}}>
                                            { previewsVideo.length > 0 &&  previewsVideo.map((previewUrl, index, file) => (
                                                <div key={index} style={{ position: "relative", marginRight: "5px", marginBottom: "8px", height: "75px", width: "75px",  zIndex: "1" }}  >
                                                  
                                                 <video loading="lazy" style={{ width: "100%" , height: "100%", borderRadius: "5px" }}  src={previewUrl} alt={`Preview ${index}`} />  
                                                 <span  style={{position:"absolute",cursor: "pointer",top: "-2px",right: "2px", fontSize: "20px", color:"limegreen"}}
                                                   onClick={() => deleteVideo(index)}>&times;</span>
                                                </div>
                                            ))}
                                        </div> */}
                                 <label for="toemail">Template</label>
                                <select class="form-select mb-2 form-select" name="template" id="template" value={template} onChange={handleTemplateChange}>
                                    <option value="">Select Template</option>
                                    {email_template.map((item) => (
                                    <option value={item.template_name}>{item.template_name}</option>
                                    ))}
                                </select>
                                </div>
                                </div>

                        {/* <div class="form-group">
                                <label for="bcc">Bcc</label>
                                <input class="form-control col" type="text" name="bcc" id="bcc" value={bcc} placeholder="Enter Bcc recipients"
                                onChange={(event) => { setBcc(event.target.value); }}/>
                            </div> */}
                   
                       
                        <div class="col-md-12">
                        <div class="form-group">
                                <label for="toemail">Subject</label>
                                <input class="form-control col" type="text" name="subject" id="subject" value={subject} onChange={(event) =>{ setSubject(event.target.value); }}
                                 placeholder="Enter Subject"/>
                                <span class="text-danger">{email_error_list.subject}</span> 
                            </div>
                        </div>

                
                        
                         

                        <div class="col-md-12">
                        <div class="form-group">
                            <label for="toemail">Message</label>
                            <div
  id="editor-container" ref={editorContainer}
  name="message"
  value={message}
  style={{ height: '600px', border: '1px solid #ccc' }}
  onChange={(event) => { setMessage(event.target.value); }}
></div>
</div>


                             <span class="text-danger">{email_error_list.message}</span> 
                            
                        </div>
                        
                        <div class="d-flex align-items-center justify-content-between">
                           



                            {successMessage && <p class="text-left" style={{color:"green"}}>{successMessage}</p>}
                            <div>

                                <button onClick={sendEmail} class="btn btn-sm btn_secondary text-white mt-2 mb-3"><i class="bi bi-send"> </i>{loading?"Sending...":"Send Message"} </button>
                            </div>

                            
                        </div> 
                        {/* <div className="container"  style={{ maxHeight: "200px", overflowY: "auto", display: "flex" , padding:"15px"}}>
                        { previews.length > 0 && previews.map((preview, index) => (
                                                <div key={index} style={{ position: "relative", marginRight: "5px", marginBottom: "8px", height: "75px", width: "75px" }}  >
                                                   <span  style={{position:"absolute",cursor: "pointer",top: "-2px",right: "2px", fontSize: "20px", color:"limegreen"}}
                                                   className="delete" onClick={() => deleteImage(index)}>&times;</span>
                                                {preview.type === 'image' && (
                                                <img
                                                    loading="lazy"
                                                    onClick={() => handleImageClick(index)}
                                                    style={{ width: "100%", height: "100%", borderRadius: "5px" }}
                                                    src={preview.url}
                                                    alt={`Preview ${index}`}
                                                />
                                            )}
                                    
                                    {preview.type === 'pdf' && (
                                                
                                                    <img src="/img/pdf_icon.jpg" alt={`Preview ${index}`}  onClick={() => handlePdfClick(index)} 
                                                    style={{ width: "100%", height: "100%", borderRadius: "5px" }}/>
                                                
                                             
                                            )}
                                    
                                    {preview.type === 'video' && (
                                             
                                                <video  src={preview.url} alt={`Preview ${index}`}  onClick={() => handleVideoClick(index)} 
                                                style={{ width: "100%", height: "100%", borderRadius: "5px" }}/>
                                           
                                        )}
                                                </div>
                                            ))}
                                        </div> */}
                    </div>
                    </div>
            </div>

        </div>

<div class="content_wrapper pt-0">
   
    <div class="vm_content_box_1 mt-4">
        <div class="title">
            <h2>Email Record List</h2>
        </div>

        <div class="content">
        <div class="row pt-3">
                          
                          <div class="col-md-9 form-group" >
                                  <div class="d-flex input_wrapper">
                                  <div ><label>Show   <select name="example_length" aria-controls="example" value={perPage} onChange={(e) => {setCurrentPage(0);setPerPage(Number(e.target.value))}}>
                                      <option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option>
                                    </select>  entries</label>
                                    </div>
                              
                   
                                  </div>
                              </div>
  
                              <div class="col-md-3 form-group">
                                  <div class="d-flex input_wrapper">
                                      <div class="icon_wrapper">
                                          <i class="bi bi-search icon"></i>
                                      </div>
                                      <input class="form-control" name="searchQuery" id="searchQuery" placeholder="Search..." type="text" 
                                      value={searchQuery} onChange={(e) => {setSearchQuery(e.target.value)}}/>
                                  </div>
                              </div>
                          </div>

                         
                            <><table class="display dataTable dtr-inline" style={{ width: "100%" }}>
                                    <thead>
                                        <tr>
                                            <th>To</th>
                                            <th>Subject</th>
                                            {/* <th>Vehicle list Link</th> */}
                                            <th>Sent at</th>
                                        </tr>
                                    </thead>


                                    <tbody>
                                    {filteredResult.length === 0 ? 
                

                <div class="row" style={{margin: "10px -900px 10px 10px"}}>
                <p id="recordMessage" valign="top" class="odd" style={{ textAlign: "center" }} >No records found</p> 
                </div>
                  : null} 

                                        {filteredResult.slice((currentPage * perPage), ((currentPage * perPage) + perPage)).map((item, index) => (
                                            <tr key={index}>

                                                <td>
                                                    <p>{item.to}</p>
                                                </td>

                                                <td>
                                                    <p>{item.subject}</p>
                                                </td>
                                                {/* <td>
    {item.message.match(/(https?:\/\/[^\s]+)/g) && item.message.match(/(https?:\/\/[^\s]+)/g).find(url => url.includes("vehicle-list")) ? (
        <a href={item.message.match(/(https?:\/\/[^\s]+)/g).find(url => url.includes("vehicle-list"))} target="_blank">View Vehicle List</a>
    ) : (
        "No vehicle list available"
    )}
</td> */}
                                                <td>
                                                    <p>{formatDate(item.created_at)}</p>
                                                </td>    
                                            </tr>


                                        ))}
                                    </tbody>

                                    <tfoot>
                                        <tr>
                                            <th>To</th>
                                            <th>Subject</th>
                                            {/* <th>Vehicle list Link</th> */}
                                            <th>Sent at</th>
                                        </tr>
                                    </tfoot>
                                </table><div class="col-md-3" style={{ float: "left", padding: "20px 0px 0px 0px" }}>
                                        {filteredResult.length > 0 && `Showing 1 to ${Math.min(perPage, filteredResult.length)} of ${filteredResult.length} entries`}
                                    </div><div class="col-md-9" style={{ float: "right" }}>

                                        <ReactPaginate style={{ float: "right" }}
                                            previousLabel={"< Prev"}
                                            nextLabel={"Next >"}
                                            breakLabel={'...'}
                                            pageCount={Math.ceil(filteredResult.length / perPage)}
                                            onPageChange={handlePageChange}
                                            containerClassName={"pagination pagination-sm justify-content-center custom-pagination"} // Bootstrap pagination class
                                            disabledClassName={"disabled"}
                                            breakClassName={['page-item']}
                                            breakLinkClassName={'page-link'}
                                            pageClassName={'page-item'}
                                            pageLinkClassName={'page-link'}
                                            previousClassName={'page-item'}
                                            previousLinkClassName={'page-link'}
                                            nextClassName={'page-item'}
                                            nextLinkClassName={'page-link'}
                                            activeClassName={['active']} />


                                    </div></>
                          

        </div>
    </div>
</div>

</div>



</div>
    );
}
export default EmailLogs;