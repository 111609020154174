import SideNav from "./SideNav";
import TopBar from "./TopBar";
import React, { useState,  useEffect, useRef } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'datatables.net-dt/css/jquery.dataTables.css';
import $ from 'jquery';
import 'datatables.net';
import { Link} from "react-router-dom";
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import Swal from 'sweetalert2';
import { Modal, Button } from 'react-bootstrap';

function ViewCustomers(){
    const API_URL = process.env.REACT_APP_API_URL;
    const tableRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [customer, setCustomer] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(100);
    const [error_list, setErrorList] = useState([]);
    const [selectedCustomers, setSelectedCustomers] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);  
    const [label_name, setLabel_name] = useState("");
    const [loading, setLoading] = useState(false);
    

    const filteredResult = customer.filter((item) => {
        return (item.email && item.email.toLowerCase().includes(searchQuery.toLowerCase())) ||
               (item.phone_no && item.phone_no.toLowerCase().includes(searchQuery.toLowerCase())) ||
               (item.first_name && item.first_name  .toLowerCase().includes(searchQuery.toLowerCase()));
      });

      const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
      };

    useEffect(() => { 
        setIsLoading(true);
          AOS.init({ duration: 1200 });
          getCustomerList();
}, []);



// useEffect(() => {
//     $(tableRef.current).DataTable();
//   }, []);


  async function getCustomerList(){
 
    axios.get(`${API_URL}/view-customer`).then(res=>{
        if(res.data.status === 200){
          const customer = res.data.customer;
            setCustomer(res.data.customer);
        }
        else if(res.data.status === 404){
            //setMessage(res.data.message);
            //console.log(res.data.status);
            }
            
        setIsLoading(false);
     });
       
    }

    const deleteCustomer = (e,id) =>{
        e.preventDefault();
        var buttonClicked = window.confirm("Are you sure you want to delete Customer record?");
        if(buttonClicked == true){
           // const thisClicked = e.currentTarget;
          //  thisClicked.innerText = "Deleting";
            axios.delete(`${API_URL}/delete-customer/`+id).then(res => {  
               
          Swal.fire({
                  icon:"success",
                  text:res.data.message
                }).then(function(){  
                  
                       
                         // thisClicked.closest("tr").remove();                           
              });
              getCustomerList();
            })
        }
        else{
            getCustomerList();
        }
 
       }

       const handleCustomerSelect = (e, id) => {
        if (e.target.checked) {
          setSelectedCustomers((prev) => [...prev, id]);
        } else {
          setSelectedCustomers((prev) => prev.filter(customerId => customerId !== id));
        }
      };
    
      // Open the modal
      const openModal = () => {
        if (selectedCustomers.length > 0) {
          setIsModalOpen(true);
        } else {
          alert("Please select a customer first!");
        }
      };
    
      // Close the modal
      const closeModal = () => {
        setIsModalOpen(false);
        setLabel_name(""); // Clear the input
      };
    
      async function handleCreateLabel (event)
      {
        event.preventDefault();
        setLoading(true);
        const formData = new FormData()
          formData.append('label_name', label_name);
          formData.append('customer_id', JSON.stringify(selectedCustomers))
          console.log(selectedCustomers)  
      
            await axios.post(`${API_URL}/save-label`, formData).then(res =>{
      
              Swal.fire({
                  icon:"success",
                  text:res.data.message
                }).then(function(){     
                  setLoading(false);        
                     resetData();
                     setSelectedCustomers([]);
                     setErrorList([]);
                     closeModal();
                     
              });
              }).catch(function(error) {
                // if(error.response.status===422){    
                   
                setErrorList(error.response.data.validate_err);
                // }
                // else{
                  Swal.fire({
                    text:error.response.data.message,
                    icon:"error"
                  })
                  setLoading(false);    
                // }
              })
             
      }
      const resetData = () => {
        setLabel_name("");
        };
    
        const handleSelectAll = (isChecked) => {
          if (isChecked) {
              // Select all customers in the filtered result
              setSelectedCustomers(filteredResult.map(item => item.id));
          } else {
              // Deselect all customers
              setSelectedCustomers([]);
          }
      };
      

    return(
        <div>
<SideNav/>
      <TopBar/>

      {isLoading?
     <div className="loader-container" style ={{backgroundColor: "rgba(0, 0, 0, 0.5)", position: "fixed",top: "0",left: "0",width: "100%",height: "100%",display: "flex",justifyContent: "center",alignItems: "center",zIndex: "9999"}}>
    <div class="d-flex justify-content-center">
  <div class="spinner-border" role="status">
  </div>
  </div>
</div>:null}
      <div class="right_panel position contact_section vh-100" id="right_panel">

        <div class="main_title" >
            <h1><i class="bi bi-people"></i> Customers</h1>
           
                <button class="btn btn-sm btn_secondary">
                <Link style={{color:"white"}} to={'/create-customer'}>Create New Customer </Link>
                  </button>
          
        </div>

        <div class="content_wrapper pt-0">
            
            <div>
                <div class="vm_content_box_1">
                    <div class="content">

                    <div class="row pt-3">
                          
                          <div class="col-md-6 form-group" >
                                  <div class="d-flex input_wrapper">
                                  <div><label>Show   <select name="example_length" aria-controls="example" value={perPage} onChange={(e) => {setCurrentPage(0);setPerPage(Number(e.target.value))}}>
                                      <option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option>
                                    </select>  entries</label>
                                    </div>
                                  </div>
                              </div>
  
                              <div className="col-md-3 form-group d-flex justify-content-end">
      {selectedCustomers.length > 0 && (
        <button className="btn btn-sm btn_secondary" onClick={openModal}>
        Create Label
      </button>
      )}
      </div>
      {isModalOpen && (
  <Modal
    show={isModalOpen} // Modal visibility control
    onHide={closeModal} // Close modal on clicking outside or the close button
    centered // Centers the modal vertically
    aria-labelledby="createLabelModalLabel"
  >
    <Modal.Header closeButton>
      <Modal.Title id="createLabelModalLabel">Create Label</Modal.Title>
    </Modal.Header>

    <Modal.Body>
      <div className="form-group md-12">
        <input
          type="text"
          className="form-control"
          placeholder="Enter label name"
          value={label_name}
          onChange={(e) => setLabel_name(e.target.value)}
        />
      </div>
    </Modal.Body>

    <Modal.Footer>
      {/* Your custom button styling */}
      <button type="button" className="btn btn-sm btn_secondary" onClick={handleCreateLabel}>
        Create
      </button>
    </Modal.Footer>
  </Modal>
)}



                              <div class="col-md-3 form-group">
                                  <div class="d-flex input_wrapper">
                                      <div class="icon_wrapper">
                                          <i class="bi bi-search icon"></i>
                                      </div>
                                      <input class="form-control" name="searchQuery" id="searchQuery" placeholder="Search..." type="text" 
                                      value={searchQuery} onChange={(e) => {setSearchQuery(e.target.value)}}/>
                                  </div>
                              </div>
                          </div>

                        

                        <table class="display inventory_table_list dataTable dtr-inline" style={{width:"100%"}}>
                            <thead>
                                <tr>
                                     <th>  <input
                type="checkbox" style={{ 
                  verticalAlign: 'bottom', 
                  }}
                onChange={(e) => handleSelectAll(e.target.checked)}
                checked={filteredResult.length > 0 && selectedCustomers.length === filteredResult.length}
            /></th>
                                    <th>Customer Name</th>
                                    <th>Email</th>
                                    <th>Phone</th>
                                    {/* <th>Vehicle Name</th>
                                    <th>Progress</th> */}
                                    <th class="d-flex justify-content-md-end">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                            {filteredResult.length === 0 ? 
                

                <div class="row" style={{margin: "10px -900px 10px 10px"}}>
                <p id="recordMessage" valign="top" class="odd" style={{ textAlign: "center" }} >No records found</p> 
                </div>
                  : null} 
                            {filteredResult.slice((currentPage * perPage), ((currentPage * perPage) + perPage)).map((item, index) => (
                                           
                                <>
                                {/* <tr data-aos="fade-right" data-aos-delay="" data-aos-duration="1200"> */}
                                   <tr>
                                   <td>
  <input 
    type="checkbox" style={{ 
      verticalAlign: 'bottom', 
      }}
    onChange={(e) => handleCustomerSelect(e, item.id)} 
    checked={selectedCustomers.includes(item.id)}
  />
</td>

                                    <td>
                                        <div class="d-flex align-items-start">
                                            {/* <input class="mt-1 me-2" type="checkbox" name="select" id="ramesh_kumar" /> */}
                                            <label for="ramesh_kumar">
                                                {/* <p class="vm_font_semi_bold">{item.job_title}</p> */}
                                                <p>{item.first_name} {item.last_name}</p>
                                            </label>
                                        </div>
                                    </td>
                                    <td>
                                    <p >{item.email}</p>
                                    </td>
                                    <td>
                                    <p >{item.phone_no}</p>
                                        </td>
                                    {/* <td>
                                        <div class="d-md-flex align-items-center">
                                            <div class="circle_img bg-light" style={{ background: `url('https://www.topgear.com/sites/default/files/cars-car/image/2021/02/cx-5-skyactiv-g-awd-gt-sport-auto-action-3.jpg')` }}></div>
                                            <div class="ps-md-2">
                                                <p class="vm_font_semi_bold">Mazda 2022 - $11000 - M:18000Km</p>
                                                <p>Posted by Carloan Master Date : 14-02-2023 </p>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <p class="text-center vm_font_semi_bold">50%</p>
                                        <div class="progress" style={{ height: "5px" }}>
                                            <div class="progress-bar" role="progressbar" style={{ width: "50%", ariaValuenow: "50", ariaValuemin: "0", ariaValuemax: "100" }}></div>
                                        </div>
                                    </td> */}
                                    <td >
                                        <div class="d-flex justify-content-md-end" style={{margin:"7px 0px 0px 0px"}}>
                                        {/* <button class="btn_light mt-1 me-1"><i class="bi bi-eye-fill"></i> View</button> */}
                                        <Link style={{color:"white"}} to={`/edit-customer/${item.id}`} class="btn btn-sm btn_secondary " data-bs-target="#createNewCustomre"><i class="bi bi-pencil-square"></i></Link>
                                            {/* <button class="btn_light mt-2 me-2" ><Link style={{color:"#747474", background:"transparent"}} to={`/edit-customer/${item.id}`}><i class="bi bi-pen-fill"></i> Edit </Link>
                                            </button> */}
                                            <button class="btn btn-sm btn_secondary" data-bs-target="#createNewCustomre" style={{ background: "red", margin: "0px 0px 0px 10px" }}
                                     onClick={(e) => deleteCustomer(e, item.id)}><i class="bi bi-trash3"></i></button>
                               </div>
                                    </td>
                                </tr></>
))}
                            </tbody>
                            <tfoot>
                                <tr>
                                <th>
          
        </th>
                                    <th>Customer Name</th>
                                    <th>Email</th>
                                    <th>Phone</th>
                                    {/* <th>Vehicle Name</th>
                                    <th>Progress</th> */}
                                    <th class="d-flex justify-content-md-end">Action</th>
                                </tr>
                            </tfoot>
                        </table>
                        <div class="col-md-3" style={{ float: "left", padding: "20px 0px 0px 0px" }}>
                                        {filteredResult.length > 0 && `Showing 1 to ${Math.min(perPage, filteredResult.length)} of ${filteredResult.length} entries`}
                                    </div><div class="col-md-9" style={{ float: "right" }}>

                                        <ReactPaginate style={{ float: "right" }}
                                            previousLabel={"< Prev"}
                                            nextLabel={"Next >"}
                                            breakLabel={'...'}
                                            pageCount={Math.ceil(filteredResult.length / perPage)}
                                            onPageChange={handlePageChange}
                                            containerClassName={"pagination pagination-sm justify-content-center custom-pagination"} // Bootstrap pagination class
                                            disabledClassName={"disabled"}
                                            breakClassName={['page-item']}
                                            breakLinkClassName={'page-link'}
                                            pageClassName={'page-item'}
                                            pageLinkClassName={'page-link'}
                                            previousClassName={'page-item'}
                                            previousLinkClassName={'page-link'}
                                            nextClassName={'page-item'}
                                            nextLinkClassName={'page-link'}
                                            activeClassName={['active']} />


                                    </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        </div>   



    );
}
export default ViewCustomers;
